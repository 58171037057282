<template>
  <div class="context-param">
	<div class="form-group" v-for="(rec,i) in model" style="padding-left:40px;">
          <Input :size=12 v-model=model[i].entity :extra=i @change=emit class="col-sm-3"/>
	  <TextArea :size=12 :rows=3 v-model=model[i].rad_pairs :extra=i change=emit class="col-sm-8"/>
	  <Icon name=trash @click=deleteContext(i) />
     </div>
  <Icon name=plus @click=addContext />
  </div>
</template>

<script>
import darsan from "darsan"
import Input from "common/visual/input/Input.vue"
import TextArea from "common/visual/input/TextArea.vue"

export default {
  name: "ContextPairsNew",
  
  components: {Input, TextArea},
  
  data()
  {
    return {
      model: [],
    }
  },
  
  methods: {
    async deleteContext(i)
    {
      this.model.splice(i, 1)
      this.emit()
    },
    
    addContext()
    {
      this.model.push({entity: "", rad_pairs: ""})
      this.emit()
    },
    
    emit()
    {
      this.$emit("input", this.model)
    }
  },
  
}
</script>

<style scoped>
.context-param .row .glyphicon{
        align-self:center;
}

.context-param > .form-group > .form-group{
       display: initial !important;
}

.context-param label{
       display: none;
}
</style>
