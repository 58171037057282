var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SearchTable',{ref:"table",attrs:{"name":"pppoe-log","title":"История сессий PPPoE","columns":_vm.columns,"dynamic":"","apiDomain":_vm.domain,"apiTopic":"client","apiPath":"/pppoe-log?paged=1","metaApiTopic":"client","metaApiPath":"/meta/pppoe-log/search","state":_vm.tableState},scopedSlots:_vm._u([{key:"duration",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(_vm.common.formatUptime(rec.duration))+"\n  ")]}},{key:"download",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(_vm.common.formatHexSize(rec.input_octets64))+"\n  ")]}},{key:"upload",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(_vm.common.formatHexSize(rec.output_octets64))+"\n  ")]}},{key:"uid",fn:function(ref){
var rec = ref.rec;
return [_c('a',{attrs:{"href":("/client/" + (rec.uid))}},[_vm._v(_vm._s(rec.uid))])]}},{key:"nas",fn:function(ref){
var rec = ref.rec;
return [_c('a',{attrs:{"href":("/nas/" + (rec.nas))}},[_vm._v(_vm._s(_vm.nasName(rec.nas)))])]}},{key:"mac",fn:function(ref){
var rec = ref.rec;
return [_c('a',{attrs:{"data-direct-link":"true","target":"_blank","href":("/device/switch?macClient=" + (rec.mac))}},[_vm._v("\n          "+_vm._s(rec.mac)+"\n    ")])]}},{key:"ip",fn:function(ref){
var rec = ref.rec;
return [_c('span',{class:rec.error_pool ? 'text-danger' : 'text-success',attrs:{"title":_vm.common.formatErrorPoolDesc.text}},[(_vm.common.formatErrorPoolDesc.ico)?[_c('Icon',{attrs:{"name":_vm.common.formatErrorPoolDesc.ico}})]:_vm._e(),_vm._v("\n        "+_vm._s(rec.ip)+"\n    ")],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }