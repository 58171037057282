<template>
<div class="col-md-12">
  <div class="panel panel-default" v-for="q in queues" :key=q.queue >
    <div class="panel-heading">{{q.queue}} &mdash; {{q.queue_name}}</div>
    <div class="panel-body">
      <div class="row">

        <div class="col-md-9">
          <OperatorList :list=q.members />
        </div>
  
        <div class="col-md-3">
          <div style="padding: 0 20px"">
            <AbonList :list=q.abonents />
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
</template>

<script>

import AbonList from "phone/page/AbonList.vue"
import OperatorList from "phone/page/OperatorList.vue"
import darsan from "darsan"

export default {
  name: "Phone",
  
  components: {AbonList, OperatorList},
  
  async created()
  {
    this.queues = await darsan.get("", "phone", "/asterisk/queue")
  },
  
  mounted()
  {
    announcers.forEach( ann => 
    {
      ann.whenReady().then( () =>
      {
        ann.subscribe("asterisk.queue.#")
        ann.listen({
          "asterisk.queue.abonent.join": msg => this.addAbonent(msg),
          "asterisk.queue.abonent.leave": msg => this.deleteAbonent(msg),
          "asterisk.queue.member.change": msg => this.changeMemberStatus(msg),
        })
      })
    })
  },
  
  unmounted()
  {
    announcers.forEach( ann => 
    {
      ann.whenReady().then( () =>
      {
        ann.unsubscribe("asterisk.queue.#")
        ann.unlisten([
          "asterisk.queue.abonent.join",
          "asterisk.queue.abonent.leave",
          "asterisk.queue.member.change",
        ])
      })
    })
  },
  
  data()
  {
    return {
      queues: [],
    }
  },
  
  methods: {
    addAbonent(msg)
    {
      const qi = this.queues.findIndex(el => el.queue == msg.queue)
      if (qi==-1) return

      this.queues[qi].abonents.splice(0, 0, {
        position: +msg.position,
        caller: msg.caller,
        number: msg.number,
        id: msg.id,
        waited: 0,
      })
    },
    
    deleteAbonent(msg)
    {
      const qi = this.queues.findIndex(el => el.queue == msg.queue)
      if (qi==-1) return
      
      const i = this.queues[qi].abonents.findIndex(el => el.id == msg.id)
      if (i != -1) this.queues[qi].abonents.splice(i, 1)
    },
  
    changeMemberStatus(msg)
    {
      const qi = this.queues.findIndex(el => el.queue == msg.queue)
      if (qi==-1) return

      const i = this.queues[qi].members.findIndex(el => el.worker == msg.worker)
      
      if (msg.status=="unavailable")
      {
         // Оператор ушел из очереди
         if (i != -1) this.queues[qi].members.splice(i, 1)
         return
      }

      const newRec = Object.assign(this.queues[qi].members[i], {
          caller: msg.caller,
          number: msg.number,
          status: msg.status,
          calls_taken: +msg.calls_taken,
          connected: 0, 
        })
        
      if (msg.last_call) newRec.last_call = +msg.last_call

      if (i == -1)
      {
        // Оператор появился в очереди
        this.queues[qi].members.splice(0, 0, newRec)
      }
      else
      {
        // Оператор был в очереди, обновляем данные
        this.queues[qi].members.splice(i, 1, newRec)
      }
    },
  }
}

// очередь 701 не присылает обновления
</script>