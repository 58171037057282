<template>
<div>
<div v-if="ready">
  <div class="col-md-3 text-center">
    <span class="btn btn-default btn-file" style="border-color: transparent;">
      <img src="/avatar/!new" class="img-circle" style="max-width: 120px; webkit-box-shadow: 1px -1px 5px 1px rgba(0,0,0,0.75);-moz-box-shadow: 1px -1px 5px 1px rgba(0,0,0,0.75); box-shadow: 1px -1px 5px 1px rgba(0,0,0,0.75);">
      <form action="#">
       <input type="file" name="image" id="image" >
      </form>
    </span>
  </div>

  <div class="col-md-9">
    <form class="form-horizontal" autocomplete="off">

      <!-- Hack for auto fill input password -->
      <input name="username" class="hidden">
      <input name="password" type="password" class="hidden">
      <input name="telegram_id" class="hidden">
      <!-- hack end -->

     <div class="well well-sm">
      <Select label="Статус" :size=7 :label-size=2 v-model="model.disabled" :error="err.disabled" :options=disabledOptions @input="err.disabled=null" />
      <Input label="Фамилия" :size=7 :label-size=2 v-model="model.surname" :error="err.surname" @input="err.surname=null" />
      <Input label="Имя" :size=7 :label-size=2 v-model="model.name" :error="err.name" @input="err.name=null" />
    </div>

    <div class="well well-sm">
      <Input label="Логин" :size=7 :label-size=2 v-model="model.entity" :error="err.entity" @input="err.entity=null" />
      <Input label="Пароль" :size=7 :label-size=2 v-model="model.password" :error="err.password" @input="err.password=null" />
   </div>

   <div class="well well-sm">
     <Select label="Должность" :size=7 :label-size=2 v-model="model.titleId" :error="err.titleId" :options=titleOptions with-blank @input="err.titleId=null" />
     <Select label="Доступ к устройствам" :size=7 :label-size=2 v-model="model.device_access" :error="err.device_access" :options=devAccessOptions @input="err.device_access=null" />
   </div>

   <div class="well well-sm" >
     <Input label="Телефон" :size=7 :label-size=2 v-model="model.phone" :error="err.phone" @input="err.phone=null" />
     <Input label="SIP" :size=7 :label-size=2 v-model="model.sip" :error="err.sip" @input="err.sip=null" />
     <Input label="Telegram ID" :size=7 :label-size=2 v-model="model.telegram_id" :error="err.telegram_id" @input="err.telegram_id=null" />
   </div>
   
     <div class="btn btn-primary" @click=submit ><Icon name=save /> Сохранить</div>
   </form>

  </div>
</div>

<template v-else>
<SkelInput v-for="i in 9" :key=i />
</template>

</div>
</template>

<script>
import darsan from "darsan"
import {notifyError, notifyHttpError} from "common"
import {goto} from "navigation"

import Input from "common/visual/input/Input.vue"
import Select from "common/visual/input/Select.vue"
import SkelInput from "common/visual/SkelInput.vue"

export default {
  name: "WorkerNew",
  components: {Input, Select, SkelInput},
  
  data() {
   return {
     ready: false,
     titles: [],
     err: {},
     model: {},
   }
  },
  
  created()
  {
    darsan.get("", "darsan", "/title").then(list => 
    {
      this.titles = list
      this.ready = true
      this.clearModel()
    })
    .catch(res => notifyHttpError(res))
  },
  
  methods: {
  
    clearModel()
    {
      for (name of Object.keys(this.model))
      {
        this.model[name] = ""
      }
    },

    validate()
    {
      const error = {} 

      for (const name of ["name", "surname", "entity", "password"])
      {
        if (!this.model[name])
        {
          error[name] = "Введите значение"
        }
      }
      
      if ( this.model.entity && this.model.entity.search(/^[-\w\_$]+$/) === -1) 
      {
        error.entity = "Логин может состоять только из латинских букв, цифр, тире и подчеркивания"
      }

      if (this.model.phone)
      {
        if (this.model.phone.substr(0,3)=="+38")
        {
          this.model.phone = this.model.phone.substr(3)
        }
          
        if (!this.model.phone.match(/\d{10}/))
        {
          error.phone = "Неправильный формат номера"
        }
      }
      
      return error
    },
    
    submit()
    {
      const error = this.validate()

      if (Object.keys(error).length)
      {
        for (const k in error)
        {
          this.$set(this.err, k, error[k])
        }

        // #629 без этого не видны изменения в err    
        this.$forceUpdate()

        return
      }
      
      const data = Object.fromEntries(Object.entries(this.model).filter(([key,el]) => el !== null && el !== undefined))

      darsan.put("", "darsan", "/worker/"+data.entity, data)
        .then(resp => 
        {
          this.clearModel()
          this.$store.commit("refreshTable", "workers")
          goto("/worker/" + data.entity)
        })
        .fail(xhr => 
        {
          var resp = xhr.responseJSON || {};
          notifyError( resp.text_ru || resp.text || xhr.status + " " + xhr.statusText + ": " + xhr.responseText );

          if (resp.name)
          {
            this.$set(this.err, resp.name, resp.text_ru || resp.text)
          }
        });

    },

  },
  
  computed: {
    disabledOptions()
    {
      return [
        {value: "true", text: "Отключен"},
        {value: "", text: "Активен"},
      ]
    },

    devAccessOptions()
    {
      return [
        {value: "", text: "Нет"},
        {value: "read-only", text: "Чтение"},
        {value: "read-write", text: "Полный"},
      ]
    },
    
    titleOptions()
    {
      return this.titles.map(el => { return {text: el.name, value: el.entity} })
    },
  },
}
</script>

<style scoped>
	.btn-file {
		position: relative;
		overflow: hidden;
	}
	.btn-file input[type=file] {
		position: absolute;
		top: 0;
		right: 0;
		min-width: 100%;
		min-height: 100%;
		font-size: 100px;
		text-align: right;
		filter: alpha(opacity=0);
		opacity: 0;
		outline: none;
		background: white;
		cursor: inherit;
		display: block;
	}
</style>