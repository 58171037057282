var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SearchTable',{ref:"table",attrs:{"name":"tplan","title":"Тарифные планы","columns":_vm.columns,"dynamic":"","apiTopic":"client","apiPath":"/tplan?paged=1","metaApiTopic":"client","metaApiPath":"/meta/tplan/search","toolbar":_vm.toolbar,"state":_vm.state.params},on:{"toolbar-click":_vm.toolbarClick},scopedSlots:_vm._u([{key:"per_day",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(_vm.common.fromMainCurrency(rec.per_day))+"\n  ")]}},{key:"per_month",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(_vm.common.fromMainCurrency(rec.per_month))+"\n  ")]}},{key:"type",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(_vm.typeName(rec.type))+"\n  ")]}},{key:"name",fn:function(ref){
var rec = ref.rec;
return [_c('a',{attrs:{"href":'/tplan/'+rec.id}},[_vm._v(_vm._s(rec.name))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }