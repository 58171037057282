<template>
<div style="margin-top: 1em">
    <div class="col-md-3 text-center">
        <ol class="breadcrumb">
          <li><a :href="`/worker/${this.state.entity}`">{{this.state.entity}}</a></li>
          <li><a :href="`/worker/${this.state.entity}/permission`">Права</a></li>
          <li class="active">Новое право</li>
        </ol>

    </div>

    <div class="col-md-9" >

        <div class="form-horizontal">

            <div class="well well-sm">
            
                <Select2 label="Право" :size=7 :label-size=2 v-model="tag" :options=permTagOptions />

                <div class="form-group">
                    <label for="tag" class="col-sm-2 control-label">Описание</label>
                    <div class="col-sm-7" >
                        <p class="form-control-static">{{rec.description}}</p>
                    </div>
                </div>

         <Input v-if="rec.type=='string'" label="Значение" :size=7 :label-size=2 :name=rec.name
          v-model=value  />
         <Number v-if="rec.type=='number'" label="Значение" :size=7 :label-size=2 :name=rec.name
          :min=rec.min :max=rec.max :step=rec.step v-model=value />
         <Select v-if="rec.type=='oneof'" label="Значение" :size=7 :label-size=2 :name=rec.name
          v-model=value :options=oneOfOptions />
         <Select v-if="rec.type=='darsan-oneof'" label="Значение" :size=7 :label-size=2 :name=rec.name
          v-model=value :options=darsanOneOfOptions />
         <Select2 v-if="rec.type=='darsan-array'" multiple label="Значение" :size=7 :label-size=2 :name=rec.name
          v-model=value :options=darsanOneOfOptions />
         <Select v-if="rec.type=='boolean'" label="Значение" :size=7 :label-size=2 :name=rec.name
          v-model=value :options=yesNoOptions />

            </div>
            <div class="btn btn-primary" @click=saveTag ><Icon name="save" /> Сохранить</div>
        </div>

    </div>
</div>

</template>

<script>

import Select from "common/visual/input/Select.vue"
import Select2 from "common/visual/input/Select2.vue"
import Input from "common/visual/input/Input.vue"
import Number from "common/visual/input/Number.vue"
import TextArea from "common/visual/input/TextArea.vue"
import SkelInput from "common/visual/SkelInput.vue"
import {notifyHttpError} from "common"
import darsan from "darsan"
import {goto} from "navigation"

export default {
  name: "WorkerTagEditor",
  
  components: {Select, Input, Number, TextArea, SkelInput, Select2},

  props: {
    state: Object,
  },
  
  data()
  {
    return {
      tag: null,
      list: [],
      value: null,
      permissions: [],
    }
  },
  
  created()
  {
    this.ensure("permission-tags").then(() => 
    {
      const type = this.rec.type
      
      if (type=="darsan-oneof" || type=="darsan-array")
      {
        darsan.get("", this.rec.topic, this.rec.path).then( list =>
        {
          this.list = list
        })
        .fail(xhr => notifyHttpError(xhr))
      }
    })
  },
  
  activated()
  {
    Promise.all([
      darsan.get("", "darsan", `/worker/${this.state.entity}/permission`),
    ])
    .then(list => 
    {
      this.permissions = list
      this.tag = this.permTagOptions[0].value
    })
    .catch(xhr => notifyHttpError(xhr))
  },
  
  methods: {
    saveTag()
    {
      darsan.put("", "darsan", this.apiPath, {value: this.value})
      .then(() => 
      {
        this.$store.commit("refreshTable", "worker-perm")
        goto(`/worker/${this.state.entity}/permissions`)
      })
      .fail(xhr => notifyHttpError(xhr))
    }
  },
  
  computed: {
    yesNoOptions() 
    {
      return [
        {value: "0", text: "false"},
        {value: "1", text: "true"},
      ]
    },
    
    oneOfOptions()
    {
      return this.rec.values.map(el => { return {text: el, value: el} })
    },
    
    darsanOneOfOptions()
    {
      return this.list.map(el => { return {text: el.display_name, value: el.entity} })
    },
    
    apiPath()
    {
      return `/worker/${this.state.entity}/permission/${this.tag}`
    },
    
    permTagOptions()
    {
      return this.$store.state.preload['/permission-tags']
        .filter(el => this.permissions.findIndex(pel => pel.tag==el.name) == -1)
        .map(el => { return {text: el.name + " - " + el.name_ru, value: el.name } })
    },
    
    rec()
    {
      return this.$store.getters.getPermTag(this.tag)
    }
  },
  
  watch: {
    tag(val) 
    {
      switch(this.rec.type)
      {
        case "string":
          this.value = ""
        break;
        case "number":
          this.value = 0
        break
        case "boolean":
          this.value = "1"
        break
        case "oneof":
          this.value = this.rec.values[0]
        break
        case "darsan-oneof":
          darsan.get("", this.rec.topic, this.rec.path)
          .then( list =>
          {
            this.list = list
            this.value = list[0].entity
          })
          .fail(xhr => notifyHttpError(xhr))
        break;
        case "darsan-array":
          darsan.get("", this.rec.topic, this.rec.path)
          .then( list =>
          {
            this.list = list
            this.value = []
          })
          .fail(xhr => notifyHttpError(xhr))
        break;
      }
    } 
  },
}
</script>