<template>
<div>
  <h4>Групповая смена устройства IPoE</h4>
  <div class="form-group">
    <label for="device">Новое устройство</label>
    <DeviceInput name="device" v-model=device />
  </div>
  <br>
  <button class="btn btn-secondary" @click=makeOp >Сменить устройство</button>
</div>
</template>

<script>
  import darsan from "darsan"
  import DeviceInput from "_lib/device-search-input/DeviceInput.vue"

  export default {
    name: "gang-change-ipoe-device",
    
    components: {DeviceInput},
    
    props: {
      query: String,
    },

    data()
    {
      return {
        device: null,
      }
    },
    
    methods: {
      makeOp()
      {
        darsan
          .post("", 'client', '/gang/ipoe/device', {
            device_entity: this.device,
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))
          .fail(err => this.common.notifyError('Ошибка ' + err.status+ ": " + err.statusText))

        this.$emit("dismiss")
      }
    },
  }
</script>
