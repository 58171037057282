define(
  [
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',

    'common/visual/visual',
    'common',

    'text-loader!device/status/modules/graph_port/main.tpl',
    'text-loader!device/status/modules/graph_port/layout.tpl'
  ],
  function($, _, Backbone, darsan, visual, common, mainTemplate, layoutTemplate) {
    var prefix;
    var advData,
      lData = { type: {} };

    lData.period = [
      { id: 'default', name: 'По умолчанию' },
      { id: 'hour6', name: '6 часов' },
      { id: 'day', name: 'Сутки' },
      { id: 'week', name: 'Неделя' },
      { id: 'month', name: 'Месяц' },
      { id: 'month3', name: '3 Месяца' }
    ];

    lData.type.switch = [{ id: 'traffic', name: 'Трафик' }, { id: 'error', name: 'Ошибки' },{ id: 'discard', name: 'Сброшенные пакеты'},{ id: 'unicast', name: 'Unicast' }, { id: 'multicast', name: 'Multicast' }, { id: 'broadcast', name: 'Broadcast' }];
    lData.type.pon = [{ id: 'traffic', name: 'Трафик' }, { id: 'error', name: 'Ошибки' },{ id: 'discard', name: 'Сброшенные пакеты'},{ id: 'unicast', name: 'Unicast' }, { id: 'multicast', name: 'Multicast' }, { id: 'broadcast', name: 'Broadcast' }];
    lData.type.radio = [{ id: 'traffic', name: 'Трафик' }, { id: 'error', name: 'Ошибки' }, { id: 'unicast', name: 'Unicast' }, { id: 'multicast', name: 'Multicast' }, { id: 'broadcast', name: 'Broadcast' }];

    return Object.create(visual).extend({
      title: 'Графики',
      name: 'devicePortGraph',
      icon: 'stats',
      create: function(el, opt) {
        var me = this;
        visual.create.apply(me, arguments);

        advData = opt.advData;

        this.fmodel = new Backbone.Model({ period: '2day' });
        this.fmodel.on('change:device change:port change:period refresh', function() {
          if (!me.fmodel.get('port')){
            me.$el.find('#graph').html('<b>Выберите порт...</b>');
            return;
          }

          var token = encodeURIComponent(localStorage.getItem('radix-token'));
          var ports = _.sortBy(String(me.fmodel.get('port')).split(','), function(v) { return parseInt(v);});
	  ports = _.map(ports,function(p){ return { port: p, tag: '?' } });

          var param = lData.type[me.fmodel.get('type')],

         graph = [];
          if (!param)
            console.error('Graph config for type ' + me.fmodel.get('type') + ' not found!');



	    //Получаем описания портов
            let d = _.map(ports, function(p) {
		return darsan.get(prefix, 'device','/' +me.fmodel.get('type') +'/' +me.fmodel.get('device') +'/port/' + p.port +'/tag').then(function(d){ p.tag = d.tag });
	    });

	  $.when.apply($,d).done(function(data){
	    _.forEach(param, function(v) {
		var pr = [];
		    _.map(ports, function(p) {
			var u = darsan.makeUrl(prefix,'device','/' +me.fmodel.get('type') +'/' +me.fmodel.get('device') +'/port/' + p.port +'/graph/' + v.id );
			pr.push({ port: p.port, tag: p.tag, url: u + '?period=' + me.fmodel.get('period') + '&darsan2=' + token });
		    });
		    graph.push({ name: v.name, ports: pr });
	     });
	     me.$el.find('#graph').html(_.template(mainTemplate)({ graph: graph }));
	  })
        });

        me.$el.html(
          _.template(layoutTemplate)({
            fmodel: this.fmodel.toJSON(),
            lData: lData,
            advData: advData
          })
        );
        me.$el.on('change', '[name="period"]', function(e) {
          me.fmodel.set({ period: $(e.currentTarget).val() });
        });

        me.$el.on('click', '#refresh', function(e) {
          me.fmodel.trigger('refresh');
        });
      },
      setState: function(state) {
        var me = this;

        me.state = state;
	prefix = state.prefix||config.domain;

        this.fmodel.set(state);
      }
    });
  }
);
