<template>
  <div class="main">
  <AllServicesLoader :dependsOn=uid v-model=allServicesFact />
  
  <div class="panel panel-default">
    <div class="panel-heading">Исходные данные</div>
    <div class="panel-body">
      <div class="row">
        <Select v-model="chosenServiceFact" :size=3 :options=allServicesOptions v-show=chosenServiceVisible label="Выберите сервис" />
      </div>

      <div class="row">
        <Select v-model="isRouterFact" :size=3 :options=yesNoOptions label="Через роутер" />
        <Select v-model="paidVisitFact" :size=3 :options=yesNoOptions label="Платный вызов" />
      </div>

      <div class="row">
        <Select v-model="chosenSessionFact" :size=3 :options=allSessionsOptions :visible="chosenSessionVisible" label="Выберите сессию" />
        <Select v-model="problemDetailFact" :size=3 :options=problemTypes :visible=true label="Тип проблемы" />
      </div>

      <div class="row">
        <Input v-model="chosenPhoneFact" :size=3 :datalist=userPhones :visible=true label="Контактный номер телефона"></Input>
      </div>

      <AllSessionLoader v-model=allSessionsFact :dependsOn=serviceFact />
      <NewsLoader v-model=newsFact :dependsOn=uid />
      <UserTechnologyLoader v-model=technologyFact :dependsOn=uid />
      <UserInfoLoader v-model=userInfoFact :dependsOn=uid />
    </div>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading">Абонент</div>
    <div class="panel-body">
      <UserProblemInd :fact=newsFact ref="userNews"/>
      <UserGroupProblemInd :fact=newsFact />
      <ServiceInd :fact=serviceFact />
      <SessionInd :fact=sessionFact />

      <PortAloneLoader v-model=aloneFact :dependsOn=serviceFact :active-if=isSwitch />
      <PortAloneInd :fact=aloneFact :visible=isSwitch ref="portAlone"/>
      
      <UserLastSessLoader v-model=lastSessionFact :dependsOn=serviceFact />
      <UserLastSessInd :fact=lastSessionFact ref="userLastSession" />
      
      <UserShortSessLoader v-model=shortSessFact :dependsOn="serviceFact && problemFact=='sometimes'" :service=serviceFact />
      <UserShortSessInd :fact=shortSessFact />

      <UserHungSessLoader v-model=hungSessFact :dependsOn="serviceFact && problemFact=='sometimes'" :service=serviceFact />
      <UserHungSessInd :fact=hungSessFact />
      
      <UserLossLoader v-model=lossFact :dependsOn="sessionFact && problemFact=='badly'" :session=sessionFact />
      <UserLossInd :fact=lossFact /> 

      <OnuAloneLoader v-model=onuAloneFact :dependsOn="onuStatusFact" :active-if=isPon :service=serviceFact />
      <OnuAloneInd :fact=onuAloneFact :visible=isPon ref="onuAlone"/>
    </div>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading">Порт / ONU</div>
    <div class="panel-body">
      <PortProblemInd :fact=newsFact ref="portNews"/>
      <PortStateInd v-model=portStateFact :fact=switchStatusFact :service=serviceFact :visible="serviceFact && isSwitch" ref="portState"/>
      <PortStatusInd v-model=portActiveFact :fact=switchStatusFact :service=serviceFact :visible="serviceFact && isSwitch" ref="portStatus"/>

      <PortMacLoader v-model=portMacsFact :dependsOn=serviceFact :active-if=isSwitch />
      <UidLoader v-model=portUidsFact :dependsOn=portMacsFact :active-if=isSwitch />

      <UidsActiveSessLoader v-model=portActiveSessFact :dependsOn="portUidsFact && aloneFact == 'not-alone'" :uids=portUidsFact :active-if=isSwitch />
      <PortActiveSessInd :fact=portActiveSessFact :visible=isSwitch />

      <UidsShortSessLoader v-model=portShortSessFact :dependsOn="portUidsFact && aloneFact == 'not-alone' && problemFact=='sometimes'" :uids=portUidsFact :active-if=isSwitch />
      <PortShortSessInd :fact=portShortSessFact :visible=isSwitch />

      <UidsHungSessLoader v-model=portHungSessFact :dependsOn="portUidsFact && aloneFact == 'not-alone' && problemFact=='sometimes'" :uids=portUidsFact :active-if=isSwitch />
      <PortHungSessInd :fact=portHungSessFact :visible=isSwitch />

      <PortTermSessLoader v-model=portTermSessFact :lastSess=lastSessionFact :service=serviceFact :alone=aloneFact :portMacs=portMacsFact
        :dependsOn="serviceFact && lastSessionFact && aloneFact == 'not-alone' && portMacsFact" :active-if=isSwitch />
      <PortTermSessInd :fact=portTermSessFact :visible=isSwitch ref="portTermSess" />

      <UidsLossLoader v-model=portLossFact :dependsOn="portUidsFact && problemFact=='badly' && aloneFact == 'not-alone'" :uids=portUidsFact :active-if=isSwitch />
      <PortLossInd :fact=portLossFact :visible=isSwitch />

      <!---->

      <OnuStatusLoader v-model=onuStatusFact :dependsOn=serviceFact :active-if=isPon />
      <OnuStatusInd :fact=onuStatusFact :visible=isPon ref="onuStatus"/>

      <OnuMacLoader v-model=onuMacsFact :dependsOn=onuStatusFact :active-if=isPon />
      <UidLoader v-model=onuUidsFact :dependsOn=onuMacsFact :active-if=isPon />

      <UidsActiveSessLoader v-model=onuActiveSessFact :dependsOn="onuUidsFact && onuAloneFact == 'not-alone'" :uids=onuUidsFact :active-if=isPon />
      <OnuActiveSessInd :fact=onuActiveSessFact :visible=isPon />

      <UidsShortSessLoader v-model=onuShortSessFact :dependsOn="onuUidsFact && onuAloneFact == 'not-alone' && problemFact=='sometimes'" :uids=onuUidsFact :active-if=isPon />
      <OnuShortSessInd :fact=onuShortSessFact :visible=isPon />

      <UidsLossLoader v-model=onuLossFact :dependsOn="onuUidsFact && problemFact=='badly' && onuAloneFact == 'not-alone'" :uids=onuUidsFact :active-if=isPon />
      <OnuLossInd :fact=onuLossFact :visible=isPon />

      <UidsHungSessLoader v-model=onuHungSessFact :dependsOn="onuUidsFact && onuAloneFact == 'not-alone' && problemFact=='sometimes'" :uids=onuUidsFact :active-if=isPon />
      <OnuHungSessInd :fact=onuHungSessFact :visible=isPon />
      
      <OnuTermSessLoader v-model=onuTermSessFact :lastSess=lastSessionFact :onu=onuStatusFact :service=serviceFact :onuMacs=onuMacsFact
        :dependsOn="onuStatusFact && lastSessionFact && onuAloneFact == 'not-alone'" :active-if=isPon />
      <OnuTermSessInd :fact=onuTermSessFact :visible=isPon ref="onuTermSess"/>

      <ServiceVlanLoader v-model=serviceVlanFact :dependsOn="serviceFact" :service=serviceFact :active-if=isPon />

    </div>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading">Коммутатор / Дерево</div>
    <div class="panel-body">
      <DeviceProblemInd :fact=newsFact ref="deviceNews"/>
      
      <SwitchStatusLoader v-model=switchStatusFact :dependsOn=serviceFact :active-if=isSwitch />
      <SwitchStatusInd :fact=switchStatusFact :visible=isSwitch />
      
      <SwitchPortsLoader v-model=switchPortsFact :dependsOn="switchStatusFact && serviceFact" :active-if=isSwitch
        :status=switchStatusFact :service=serviceFact />
        
      <SwitchMacLoader v-model=switchMacsFact :dependsOn=switchPortsFact :active-if=isSwitch />
      <SwitchUidLoader v-model=switchUidsFact :dependsOn=switchMacsFact :active-if=isSwitch />
      
      <SwitchPortOffLoader :dependsOn="serviceFact && switchStatusFact" v-model=switchPortOffFact 
        :service=serviceFact :switch=switchStatusFact v-if=isSwitch />
      <SwitchPortOffInd :fact=switchPortOffFact :visible=isSwitch />

      <SwitchActiveSessLoader v-model=switchActiveSessFact :dependsOn=switchUidsFact :active-if=isSwitch />
      <SwitchActiveSessInd :fact=switchActiveSessFact :visible=isSwitch />

      <SwitchTermSessLoader v-model=switchTermSessFact :lastSess=lastSessionFact :switch=switchPortsFact 
        :dependsOn="switchPortsFact && lastSessionFact" :active-if=isSwitch />
      <SwitchTermSessInd :fact=switchTermSessFact :visible=isSwitch ref="switchTermSess"/>

      <SwitchShortSessLoader v-model=switchShortSessFact :dependsOn="switchUidsFact && problemFact=='sometimes'" :switch=switchUidsFact :active-if=isSwitch />
      <SwitchShortSessInd :fact=switchShortSessFact :visible=isSwitch />

      <SwitchHungSessLoader v-model=switchHungSessFact :dependsOn="switchUidsFact && problemFact=='sometimes'" :switch=switchUidsFact :active-if=isSwitch />
      <SwitchHungSessInd :fact=switchHungSessFact :visible=isSwitch />

      <SwitchLossLoader v-model=switchLossFact :dependsOn="portLossFact!='no-loss' && switchUidsFact && problemFact=='badly'" :switch=switchUidsFact :active-if=isSwitch />
      <SwitchLossInd :fact=switchLossFact :visible=isSwitch />
      
      <!---->

      <TreeInfoLoader :dependsOn=serviceFact v-model=treeInfoFact :active-if=isPon />
      <TreeStatusLoader :dependsOn=onuStatusFact v-model=treeStatusFact :active-if=isPon />

      <TreeMacPresentLoader :dependsOn=treeStatusFact v-model=treeMacPresentFact :visible=isPon />
      <TreeMacPresentInd :fact=treeMacPresentFact :visible=isPon />

      <TreeMacLoader v-model=treeMacsFact :dependsOn=treeStatusFact :active-if=isPon 
        :onu=onuStatusFact />

      <TreeUidLoader v-model=treeUidsFact :dependsOn=treeMacsFact :active-if=isPon />

      <TreeOnuOffLoader v-model=treeOnuOffFact :dependsOn=treeStatusFact :active-if=isPon />
      <TreeOnuOffInd :fact=treeOnuOffFact :visible=isPon />

      <TreeShortSessLoader v-model=treeShortSessFact :dependsOn="treeUidsFact && problemFact=='sometimes'" :tree=treeUidsFact :active-if=isPon />
      <TreeShortSessInd :fact=treeShortSessFact :visible=isPon />

      <TreeHungSessLoader v-model=treeHungSessFact :dependsOn="treeUidsFact && problemFact=='sometimes'" :tree=treeUidsFact :active-if=isPon />
      <TreeHungSessInd :fact=treeHungSessFact :visible=isPon />

      <TreeLossLoader v-model=treeLossFact :dependsOn="onuLossFact!='no-loss' && treeUidsFact && problemFact=='badly'" :tree=treeUidsFact :active-if=isPon />
      <TreeLossInd :fact=treeLossFact :visible=isPon />

      <TreeActiveSessLoader v-model=treeActiveSessFact :dependsOn=treeUidsFact :active-if=isPon />
      <TreeActiveSessInd :fact=treeActiveSessFact :visible=isPon />

      <TreeTermSessLoader v-model=treeTermSessFact :dependsOn="treeStatusFact && lastSessionFact && serviceFact" :active-if=isPon :service=serviceFact :lastSess=lastSessionFact :onus=treeStatusFact />
      <TreeTermSessInd :fact=treeTermSessFact :visible=isPon ref="treeTermSess" />

    </div>
  </div>

  <div class="panel panel-default">
    <div class="panel-heading">Коммутаторы выше / OLT</div>
    <div class="panel-body">
      <!--ILabel :visible="devupCommonVisible" show="Общая проблема (новость)" /-->

      <OltStatusLoader :dependsOn=onuStatusFact v-model=oltStatusFact :active-if=isPon />

      <OltMacLoader v-model=oltMacsFact :dependsOn=oltStatusFact :active-if=isPon />
      <OltUidLoader v-model=oltUidsFact :dependsOn=oltMacsFact :active-if=isPon />

      <OltMacPresentLoader :dependsOn=oltMacsFact v-model=oltMacPresentFact :active-if=isPon />
      <OltMacPresentInd :fact=oltMacPresentFact :active-if=isPon />
      
      <OltActiveSessLoader v-model=oltActiveSessFact :dependsOn="oltUidsFact && problemFact=='sometimes'" :olt=oltUidsFact :active-if=isPon />
      <OltActiveSessInd :fact=oltActiveSessFact :visible=isPon />

      <OltShortSessLoader v-model=oltShortSessFact :dependsOn="oltUidsFact && problemFact=='sometimes'" :olt=oltUidsFact :active-if=isPon />
      <OltShortSessInd :fact=oltShortSessFact :visible=isPon />

      <OltHungSessLoader v-model=oltHungSessFact :dependsOn="oltUidsFact && problemFact=='sometimes'" :olt=oltUidsFact :active-if=isPon />
      <OltHungSessInd :fact=oltHungSessFact :visible=isPon />

      <OltLossLoader v-model=oltLossFact :dependsOn="treeLossFact!='no-onus' && oltUidsFact && problemFact=='badly'" :olt=oltUidsFact :active-if=isPon />
      <OltLossInd :fact=oltLossFact :visible=isPon />

      <OltTermSessLoader v-model=oltTermSessFact :dependsOn="oltStatusFact && lastSessionFact && serviceFact" :active-if=isPon 
        :lastSess=lastSessionFact :olt=oltStatusFact service:serviceFact />
      <OltTermSessInd :fact=oltTermSessFact :visible=isPon ref="oltTermSess" />
            
    </div>
  </div>
  
  <button type="button" class="btn btn-primary" @click=createTask>Создать заявку</button>
    
</div>
</template>

<script>

import Select from "common/visual/input/Select.vue"
import Select2 from "common/visual/input/Select2.vue"
import Input from "common/visual/input/Input.vue"

import NewsLoader from "issue/page/loaders/NewsLoader.vue"
import AllServicesLoader from "issue/page/loaders/AllServicesLoader.vue"
import AllSessionLoader from "issue/page/loaders/AllSessionLoader.vue"
import UidLoader from "issue/page/loaders/UidLoader.vue"

import UserLastSessLoader from "issue/page/loaders/UserLastSessLoader.vue"
import UserShortSessLoader from "issue/page/loaders/UserShortSessLoader.vue"
import UserHungSessLoader from "issue/page/loaders/UserHungSessLoader.vue"
import UserLossLoader from "issue/page/loaders/UserLossLoader.vue"
import UserTechnologyLoader from "issue/page/loaders/UserTechnologyLoader.vue"
import UserInfoLoader from "issue/page/loaders/UserInfoLoader.vue"

import ServiceVlanLoader from "issue/page/loaders/ServiceVlanLoader.vue"

import OnuStatusLoader from "issue/page/loaders/OnuStatusLoader.vue"
import OnuAloneLoader from "issue/page/loaders/OnuAloneLoader.vue"
import OnuMacLoader from "issue/page/loaders/OnuMacLoader.vue"
import OnuTermSessLoader from "issue/page/loaders/OnuTermSessLoader.vue"

import OltStatusLoader from "issue/page/loaders/OltStatusLoader.vue"
import OltMacLoader from "issue/page/loaders/OltMacLoader.vue"
import OltUidLoader from "issue/page/loaders/OltUidLoader.vue"
import OltShortSessLoader from "issue/page/loaders/OltShortSessLoader.vue"
import OltHungSessLoader from "issue/page/loaders/OltHungSessLoader.vue"
import OltActiveSessLoader from "issue/page/loaders/OltActiveSessLoader.vue"
import OltLossLoader from "issue/page/loaders/OltLossLoader.vue"
import OltTermSessLoader from "issue/page/loaders/OltTermSessLoader.vue"
import OltMacPresentLoader from "issue/page/loaders/OltMacPresentLoader.vue"

import PortAloneLoader from "issue/page/loaders/PortAloneLoader.vue"
import PortMacLoader from "issue/page/loaders/PortMacLoader.vue"
import PortTermSessLoader from "issue/page/loaders/PortTermSessLoader.vue"

import UidsActiveSessLoader from "issue/page/loaders/UidsActiveSessLoader.vue"
import UidsShortSessLoader from "issue/page/loaders/UidsShortSessLoader.vue"
import UidsHungSessLoader from "issue/page/loaders/UidsHungSessLoader.vue"
import UidsLossLoader from "issue/page/loaders/UidsLossLoader.vue"

import SwitchStatusLoader from "issue/page/loaders/SwitchStatusLoader.vue"
import SwitchPortsLoader from "issue/page/loaders/SwitchPortsLoader.vue"
import SwitchMacLoader from "issue/page/loaders/SwitchMacLoader.vue"
import SwitchUidLoader from "issue/page/loaders/SwitchUidLoader.vue"
import SwitchActiveSessLoader from "issue/page/loaders/SwitchActiveSessLoader.vue"
import SwitchTermSessLoader from "issue/page/loaders/SwitchTermSessLoader.vue"
import SwitchShortSessLoader from "issue/page/loaders/SwitchShortSessLoader.vue"
import SwitchHungSessLoader from "issue/page/loaders/SwitchHungSessLoader.vue"
import SwitchLossLoader from "issue/page/loaders/SwitchLossLoader.vue"
import SwitchPortOffLoader from "issue/page/loaders/SwitchPortOffLoader.vue"

import TreeStatusLoader from "issue/page/loaders/TreeStatusLoader.vue"
import TreeInfoLoader from "issue/page/loaders/TreeInfoLoader.vue"
import TreeMacPresentLoader from "issue/page/loaders/TreeMacPresentLoader.vue"
import TreeSessLoader from "issue/page/loaders/TreeSessLoader.vue"
import TreeMacLoader from "issue/page/loaders/TreeMacLoader.vue"
import TreeUidLoader from "issue/page/loaders/TreeUidLoader.vue"
import TreeShortSessLoader from "issue/page/loaders/TreeShortSessLoader.vue"
import TreeHungSessLoader from "issue/page/loaders/TreeHungSessLoader.vue"
import TreeActiveSessLoader from "issue/page/loaders/TreeActiveSessLoader.vue"
import TreeTermSessLoader from "issue/page/loaders/TreeTermSessLoader.vue"
import TreeLossLoader from "issue/page/loaders/TreeLossLoader.vue"
import TreeOnuOffLoader from "issue/page/loaders/TreeOnuOffLoader.vue"

/////////////////////////////////

import Ind from "issue/page/indicators/Ind.vue"
import ServiceInd from "issue/page/indicators/ServiceInd.vue"
import SessionInd from "issue/page/indicators/SessionInd.vue"
import SwitchStatusInd from "issue/page/indicators/SwitchStatusInd.vue"
import PortStateInd from "issue/page/indicators/PortStateInd.vue"
import PortStatusInd from "issue/page/indicators/PortStatusInd.vue"
import DeviceProblemInd from "issue/page/indicators/DeviceProblemInd.vue"

import UserProblemInd from "issue/page/indicators/UserProblemInd.vue"
import UserGroupProblemInd from "issue/page/indicators/UserGroupProblemInd.vue"
import UserLastSessInd from "issue/page/indicators/UserLastSessInd.vue"
import UserHungSessInd from "issue/page/indicators/UserHungSessInd.vue"
import UserShortSessInd from "issue/page/indicators/UserShortSessInd.vue"
import UserLossInd from "issue/page/indicators/UserLossInd.vue"

import OnuAloneInd from "issue/page/indicators/OnuAloneInd.vue"
import OnuStatusInd from "issue/page/indicators/OnuStatusInd.vue"
import OnuShortSessInd from "issue/page/indicators/OnuShortSessInd.vue"
import OnuLossInd from "issue/page/indicators/OnuLossInd.vue"
import OnuHungSessInd from "issue/page/indicators/OnuHungSessInd.vue"
import OnuActiveSessInd from "issue/page/indicators/OnuActiveSessInd.vue"
import OnuTermSessInd from "issue/page/indicators/OnuTermSessInd.vue"

import OltStatusInd from "issue/page/indicators/OltStatusInd.vue"
import OltLossInd from "issue/page/indicators/OltLossInd.vue"
import OltShortSessInd from "issue/page/indicators/OltShortSessInd.vue"
import OltHungSessInd from "issue/page/indicators/OltHungSessInd.vue"
import OltActiveSessInd from "issue/page/indicators/OltActiveSessInd.vue"
import OltTermSessInd from "issue/page/indicators/OltTermSessInd.vue"
import OltMacPresentInd from "issue/page/indicators/OltMacPresentInd.vue"

import PortAloneInd from "issue/page/indicators/PortAloneInd.vue"
import PortProblemInd from "issue/page/indicators/PortProblemInd.vue"
import PortActiveSessInd from "issue/page/indicators/PortActiveSessInd.vue"
import PortTermSessInd from "issue/page/indicators/PortTermSessInd.vue"
import PortShortSessInd from "issue/page/indicators/PortShortSessInd.vue"
import PortLossInd from "issue/page/indicators/PortLossInd.vue"
import PortHungSessInd from "issue/page/indicators/PortHungSessInd.vue"

import SwitchActiveSessInd from "issue/page/indicators/SwitchActiveSessInd.vue"
import SwitchShortSessInd from "issue/page/indicators/SwitchShortSessInd.vue"
import SwitchHungSessInd from "issue/page/indicators/SwitchHungSessInd.vue"
import SwitchLossInd from "issue/page/indicators/SwitchLossInd.vue"
import SwitchTermSessInd from "issue/page/indicators/SwitchTermSessInd.vue"
import SwitchPortOffInd from "issue/page/indicators/SwitchPortOffInd.vue"

import TreeMacPresentInd from "issue/page/indicators/TreeMacPresentInd.vue"
import TreeShortSessInd from "issue/page/indicators/TreeShortSessInd.vue"
import TreeTermSessInd from "issue/page/indicators/TreeTermSessInd.vue"
import TreeActiveSessInd from "issue/page/indicators/TreeActiveSessInd.vue"
import TreeHungSessInd from "issue/page/indicators/TreeHungSessInd.vue"
import TreeLossInd from "issue/page/indicators/TreeLossInd.vue"
import TreeOnuOffInd from "issue/page/indicators/TreeOnuOffInd.vue"

import darsan from "darsan"
import { goto } from "navigation"

export default {
  name: "Issue",
  
  components: {
    Select, Select2, Input, 
    
    NewsLoader, AllServicesLoader, AllSessionLoader, SwitchStatusLoader, PortAloneLoader,
    OnuStatusLoader, OltStatusLoader, OnuAloneLoader, TreeStatusLoader, OltMacPresentLoader,
    PortMacLoader, UidLoader, SwitchActiveSessLoader, TreeSessLoader, 
    UserLastSessLoader, PortTermSessLoader,
    SwitchTermSessLoader, UserShortSessLoader, UserLossLoader, SwitchPortsLoader,
    SwitchMacLoader, SwitchUidLoader,UidsShortSessLoader, SwitchShortSessLoader,
    UserHungSessLoader, UidsHungSessLoader, SwitchHungSessLoader, UidsLossLoader, SwitchLossLoader,
    OnuMacLoader, TreeMacLoader, TreeUidLoader, TreeHungSessLoader, TreeShortSessLoader, TreeLossLoader,
    OltMacLoader, OltUidLoader, OltShortSessLoader, OltHungSessLoader, OltLossLoader, OltActiveSessLoader,
    TreeActiveSessLoader, UidsActiveSessLoader, OnuTermSessLoader, TreeTermSessLoader, OltTermSessLoader,
    TreeMacPresentLoader, TreeOnuOffLoader, SwitchPortOffLoader, UserTechnologyLoader, UserInfoLoader, ServiceVlanLoader,
    TreeInfoLoader,
    
    ServiceInd, Ind, AllSessionLoader, SessionInd, SwitchStatusInd, PortStateInd, PortStatusInd, 
    DeviceProblemInd, UserProblemInd, UserGroupProblemInd, PortProblemInd, SwitchPortOffInd, PortAloneInd,
    OnuStatusInd, OltStatusInd, OnuAloneInd, TreeMacPresentInd, OltMacPresentInd,
    PortActiveSessInd, SwitchActiveSessInd, UserLastSessInd, PortTermSessInd, SwitchTermSessInd, UserShortSessInd,
    UserLossInd, PortShortSessInd, SwitchShortSessInd, UserHungSessInd, PortHungSessInd, SwitchHungSessInd,
    PortLossInd, SwitchLossInd, OnuLossInd, OnuShortSessInd, OnuHungSessInd, TreeLossInd, TreeShortSessInd, TreeHungSessInd,
    OltShortSessInd, OltHungSessInd, OltLossInd, OltActiveSessInd, TreeActiveSessInd, OnuActiveSessInd,
    TreeOnuOffInd, OnuTermSessInd, TreeTermSessInd, OltTermSessInd,
  },
  
  data()
  {
    return {
      uid: null,
      dialog: null,

      // Список фактов
      allServicesFact: null,
      chosenServiceFact: null,
      chosenSessionFact: null,
      chosenPhoneFact: null,
      isRouterFact: null,
      paidVisitFact: null,
      problemDetailFact: null,
      allSessionsFact: null,
      newsFact: null,
      switchStatusFact: null,
      aloneFact: null,
      lastSessionFact: null,
      shortSessFact: null,
      lossFact: null,
      hungSessFact: null,
      technologyFact: null,
      serviceVlanFact: null,

      switchPortsFact: null,
      switchMacsFact: null,
      switchUidsFact: null,

      portStateFact: null,
      portActiveFact: null,
      portMacsFact: null,
      portUidsFact: null,
      portActiveSessFact: null,
      portShortSessFact: null,
      portTermSessFact: null,
      portHungSessFact: null,
      portLossFact: null,

      switchActiveSessFact: null,
      switchTermSessFact: null,
      switchShortSessFact: null,
      switchHungSessFact: null,
      switchLossFact: null,
      switchPortOffFact: null,

      onuStatusFact: null,
      onuMacsFact: null,
      onuAloneFact: null,
      onuUidsFact: null,
      onuShortSessFact: null,
      onuHungSessFact: null,
      onuLossFact: null,
      onuActiveSessFact: null,
      onuTermSessFact: null,

      treeInfoFact: null,
      treeStatusFact: null,
      treeMacsFact: null,
      treeUidsFact: null,
      treeShortSessFact: null,
      treeHungSessFact: null,
      treeLossFact: null,
      treeActiveSessFact: null,
      treeTermSessFact: null,
      treeMacPresentFact: null,
      treeOnuOffFact: null,

      oltStatusFact: null,
      oltMacsFact: null,
      oltUidsFact: null,
      oltShortSessFact: null,
      oltHungSessFact: null,
      oltLossFact: null,
      oltActiveSessFact: null,
      oltTermSessFact: null,
      oltMacPresentFact: null,

      otherTreeMacFact: null,
      treeSessFact: null,

      userInfoFact: null,

      // Типы проблем
      problemTypes: [
        { value: "", text: "-" },
        { value: "dont_work/dont_work", text: "Не работает", descriptionCollector: this.describeDontWork },
        { value: "sometimes/short_sessions", text: "Короткие сессии" },
        { value: "sometimes/hung_sessions", text: "Зависшие сессии" },
        { value: "badly/low_speed", text: "Низкая скорость" },
        { value: "badly/slow_load", text: "Медленная загрузка" },
        { value: "badly/dont_load", text: "Контент не грузится" },
        { value: "badly/losses", text: "Потери" },
      ],
      
      yesNoOptions: [
        {value: "", text: "Нет"},
        {value: "1", text: "Да"},
      ],
    }
  },
  
  computed: {
    problemFact()
    {
      return this.problemDetailFact ? this.problemDetailFact.split("/")[0] : null
    },
  
    chosenServiceVisible()
    {
      return this.allServicesFact && this.allServicesFact.length>1
    },

    chosenSessionVisible()
    {
      return this.allSessionsFact && this.allSessionsFact.length>1
    },
    
    ///////////////////////
    
    allServicesOptions()
    {
      const list = (this.allServicesFact || []).map((el,i) => ({text: el.service, value: i}))
      list.unshift({text: "-", value: -1})
      return list
    },

    allSessionsOptions()
    {
      const list = (this.allSessionsFact || []).map((el,i) => ({text: `NAS ${el.nas}: ${el.ip}`, value: i}))
      list.unshift({text: "-", value: -1})
      return list
    },
    
    serviceFact()
    {
      if (!this.allServicesFact) return null
      if (this.allServicesFact.length == 0) return null
      if (this.allServicesFact.length == 1) return {uid: this.uid, ...this.allServicesFact[0]}

      if (this.chosenServiceFact == null) return null
      if (this.chosenServiceFact == -1) return null
      return {uid: this.uid, ...this.allServicesFact[this.chosenServiceFact]}
    },

    sessionFact()
    {
      if (!this.allSessionsFact) return null
      if (this.allSessionsFact.length == 0) return null
      if (this.allSessionsFact.length == 1) return this.allSessionsFact[0]

      if (this.chosenSessionFact == null) return null
      if (this.chosenSessionFact == -1) return null
      return this.allSessionsFact[this.chosenSessionFact]
    },
    
    isSwitch()
    {
      return this.serviceFact && this.serviceFact.device_type=='switch'
    },

    isPon()
    {
      return this.serviceFact && this.serviceFact.device_type=='pontree'
    },


    userPhones () {
      if (!this.userInfoFact) return []

      var phones = []
      const props = ['phone', 'phone2', 'phone3']

      props.forEach(phone => {
        if (this.userInfoFact[phone]) phones.push({ text: this.userInfoFact[phone], value: this.userInfoFact[phone] })
      })

      return phones
    },
  },
  
  methods: {
    setState(state) {
      this.uid = state.uid
      this.dialog = state.dialog
    },
    
    prepareTaskInfo() {
      const task = {
        type: "client-issue",
        description: this.collectDescription(),
        client: this.uid,
        device: this.serviceFact ? `/${this.serviceFact.device_type}/${this.serviceFact.device}` : null,
        task_attr: {
          uid: this.uid,
          source: "radix",
          problem_type: "",
        }
      }

      if (this.technologyFact) task.task_attr.technology = this.technologyFact
      if (this.serviceFact && this.isSwitch) task.task_attr.port = this.serviceFact.port
      if (this.switchStatusFact) task.task_attr.device_name = this.switchStatusFact.name
      if (this.treeInfoFact) task.task_attr.device_name = `${this.treeInfoFact.name} (-> ${this.treeInfoFact.head_name})`
      if (this.problemDetailFact) task.task_attr.problem_type = this.problemDetailFact.split("/")[1]
      if (this.serviceVlanFact && this.isPon) task.task_attr.vlan = this.serviceVlanFact
      if (this.chosenPhoneFact) task.task_attr.phone = this.chosenPhoneFact

      return task
    },


    collectDescription () {
      const collector = this.problemTypes.find((type) => type.value == this.problemDetailFact).descriptionCollector

      if (!collector) {
        return 'Dont know how to collect description from this problem type'
      }

      return collector()
    },


    collectBackbone () {
      var description = {}

      description.userDevice = 'Конечное устройство абонента: ' + (this.isRouterFact ? 'роутер' : 'напрямую')
      description.paidVisit  = 'Вероятен платный вызов: ' + (this.paidVisitFact ? 'да' : 'нет')

      if (this.isSwitch) {
        description.portAlone = this.$refs['portAlone'].shortStatus

        if (!this.switchStatusFact.down) {
          description.portState = this.$refs['portState'].shortStatus
        }
      }


      if (this.isPon) {
        description.onuSummary = this.onuSummaryDescription()
        description.treeState  = this.treeInfoFact.disabled ? 'Дерево: отключено' : 'Дерево: активно'
        description.oltState   = this.treeInfoFact.OltDown ? `OLT: не работает с ${this.treeInfoFact.oltDownFrom}` : `OLT: активен`
      }

      return description
    },


    createTask () {
      if (!this.problemDetailFact) {
        this.common.notifyError('Невозможно создать заявку: не выбран тип проблемы')
        return
      }

      const task = this.prepareTaskInfo()

      //console.log(task)

      darsan.postJSON("", "task", "/task", task)
      .done(task => {
        this.common.notify('Заявка создана')
        this.dialog.close()
      })
      .fail(error => this.common.notifyError('Ошибка ' + error.status+ ": " + error.statusText))
    },


    describeDontWork () {
      var description = this.collectBackbone()
      var terminationRefs = []

      description.problemType      = 'Тип проблемы: не работает интернет'
      description.news             = this.newsDescription()
      description.lastSessionTime  = 'Время завершения последней сессии: ' + this.$refs['userLastSession'].lastTime
      description.lastSessionCause = 'Причина завершения сессии: ' + this.$refs['userLastSession'].lastCause

      if (this.isSwitch) {
        if (this.switchStatusFact) {

          if (this.switchStatusFact.down) {
            description.deviceStatus = `Коммутатор: не работает с ${this.switchStatusFact.down_from}`
          } else {
            description.deviceStatus = 'Коммутатор: активен'
          }
        }

        // очередной костыль, чтобы в случаях, когда не работает устройство
        // не выводить инфу об отключившихся абонентах на порту и устройстве
        if (this.switchStatusFact && !this.switchStatusFact.down) {
          description.portStatus = this.$refs['portStatus'].shortStatus

          terminationRefs.push('switchTermSess')

          // А еще не выводить, если порт отключен
          if (this.$refs['portState'].isPortEnabled) terminationRefs.push('portTermSess')
        }
      }


      if (this.isPon) {
        terminationRefs.push('onuTermSess', 'treeTermSess', 'oltTermSess')
      }

      description.additional  = terminationRefs.map((indicator) => this.termDescription(indicator)).filter(el => !!el).join('\n')
      description.portSummary = this.portSummaryDescription(description)


      var descriptionText = [
        'problemType',
        'userDevice',
        'lastSessionTime',
        'lastSessionCause',
        'portSummary',
        'onuSummary',
        'treeState',
        'oltState',
        'deviceStatus',
        'paidVisit',
        'news',
        'additional'
      ].map(el => description[el]).filter(el => !!el).join('.\n')

      return descriptionText + '.'
    },


    newsDescription () {
      var description = []

      if (this.newsFact) {
        ['userNews', 'portNews', 'deviceNews'].forEach(el => {
          if (this.$refs[el].status) description.push(this.$refs[el].status)
        })
      }

      if (description.length) {
        return 'Новости: ' + description.join(', ')
      } else {
        return null
      }
    },


    termDescription (indicatorRef) {
      const indicator = this.$refs[indicatorRef]
      if (!indicator) return null

      if (indicator.fact) {
        // если отвалились одновременно все, то выводим без подробностей
        if (indicator.status.substr(0, 3) == 'all') {
          return indicator.status
        } else {
          if (indicator.details) return indicator.status + indicator.details
        }
      }
    },


    portSummaryDescription (description) {
      var summary = ['portAlone', 'portState', 'portStatus'].map(el => description[el]).filter(el => !!el).join(', ')
      return summary ? ('Порт: ' + summary) : null
    },


    onuSummaryDescription () {
      const onu = this.$refs['onuStatus']
      if (!onu.fact) return 'ONU: статус неизвестен'

      return `ONU: ${onu.onuName}, ${this.$refs['onuAlone'].shortStatus}, ${onu.shortStatus}, уровни: ${onu.oltRx}, ${onu.onuRx}`
    }
  },
}

</script>

<style scoped>
  .main {
    margin: 2em;
    width: 60vw;
  }
</style>
