<template>
<div>
  <h4>Групповая смена типа подключения</h4>
  <div class="form-group">
    <label for="type-select">Новый тип подключения</label>
    <select class="form-control" v-model=conntype v-if="ready">
      <option v-for="item in $store.state.preload['/client-tags-connection-type'].oneof.split(',')" :value=item>{{item}}</option>
    </select>
  </div>

  <br>
  <button class="btn btn-secondary" @click=makeOp >Сменить тип</button>
</div>
</template>

<script>
  import darsan from "darsan"

  export default {
    name: "gang-change-connection-type",
    
    props: {
      query: String,
    },

    created()
    {
      this.ensure("client-tags-connection-type").then( () => this.ready = true )
    },
    
    data()
    {
      return {
        ready: false,
        conntype: "",
      }
    },
    
    methods: {
      makeOp()
      {
        darsan.post("", 'client', '/gang/client/connection-type', {
            type: this.conntype,
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))
          .fail(err => this.common.notifyError('Ошибка ' + err.status+ ": " + err.statusText))

        this.$emit("dismiss")
      }
    },
  }
</script>
