<template>

  <div>
    <SimpleTable v-if="ready" ref=table name="loop-log" title="Петли на устройствах" :columns=columns
      apiTopic=device apiPath="/srv/loop-log" :state=state.params
    >

    <template #device_name="{rec}">
      <a @click=showDevice(rec) style="cursor: pointer;">{{rec.device_name}}</a>
    </template>

    </SimpleTable>
  </div>
</template>
  
<script>
  import Vue from 'vue/dist/vue.js'

  import SimpleTable from 'common/visual/SimpleTable.vue'

  import dialog from 'common/dialog/dialog'
  import deviceStatus from 'device/status/device-status'

  export default {
    name: "LoopLogTable",
    
    components: { SimpleTable },
  
    props: {
      state: Object,
    },
    
    data () {
      return {
        ready: false,

        columns: [
          {
            name: 'device_name',
            title: 'Устройство'
          },
          {
            name: "message",
            title: "Сообщение"
          },
        ],
        
      }
    },
    
    created () {
      this.ready = true
      console.log(deviceStatus)
    },
    
    activated () {
      if (this.$refs.table) this.$refs.table.$emit("reloadTable")
    },
    
    methods: {
      showDevice (rec) {
        dialog.showModule(Object.create(deviceStatus), {}, { device: rec.device_id, type: rec.device_kind })
      }
    },
  }
</script>
