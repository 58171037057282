<template>
  <div>

      <div class="col-md-3 text-center">
        <span class="lead">Новый сервер доступа</span>
      </div>
      
      <div class="col-md-9">
        <form class="form-horizontal">

          <div class="well well-sm">
            <Select label="Статус" :size=7 v-model="model.disable" :error=err.disable
            :options=disableOptions
            @input="err.disable=null" />

            <Select label="Тип" :size=7 v-model="model.type" :error=err.type :options="$store.state.preload['/nas-types']" @input="err.type=null" />
            <Input label="Название" :size=7 v-model="model.name" :error=err.name @input="err.name=null" />
            <Input label="Описание" :size=7 v-model="model.description" :error=err.description @input="err.description=null" />
          </div>

          <div class="well well-sm">
            <Input label="IP" :size=7 v-model="model.ip" :error=err.ip @input="err.ip=null" />

            <Input label="Собственный пул" :size=7 v-model="model.own_pool" :error=err.own_pool
            info="Название пула, определенного на сервере доступа, из которого отдаются адреса абонентам" @input="err.own_pool=null" />

            <Select2 label="Пулы биллинга" :size=7 v-model="model.ippool" :error=err.ippool 
             :multiple=true :options=ipPoolOptions @input="err.ippool=null"
             info="Пулы для сервера доступа, адреса в которых распределяет биллинг" />
    
            <Select label="Есть пулы ошибок" :size=7 v-model="model.has_error_pools" :error=err.has_error_pools
              :options=yesNoOptions
              info="Пулы ошибок error-* определены на сервере доступа, а не в биллинге" @input="err.has_error_pools=null" />
          </div>
         
          <div class="well well-sm">
            <Input label="Время alive" :size=7 v-model="model.alive" :error=err.alive
            info="Интервал обновления данных о сессии. При 6 просроченных интервалах сессия считается завершившейся." @input="err.alive=null" />

            <TextArea label="Параметры скорости" :size=7 v-model="model.speed_pairs" :error=err.speed_pairs info="Формат строк:
              Attribute := Value Установить атрибут
              Attribute = Value Установить атрибут, если у него не было значения
              Attribute += Value Добавить атрибут к списку
              Можно использовать подстановки {SPEED} {SPEED8} {SPEED4} {SPEED_KB}" @input="err.speed_pairs=null" />

            <TextArea label="Параметры RADIUS" :size=7 v-model="model.rad_pairs" :error=err.rad_pairs info="Формат строк:
              Attribute := Value Установить атрибут
              Attribute = Value Установить атрибут, если у него не было значения
              Attribute += Value Добавить атрибут к списку" @input="err.rad_pairs=null" />

            <Input label="URL съема трафика" :size=7 v-model="model.traffic_url" :error=err.traffic_url
            info="Адрес вебсокета, отдающего данные по трафику с данного сервера доступа" @input="err.traffic_url=null" />
          </div>
          

          <div class="well well-sm">
            <Collapse title="Контекстные параметры">
              <ContextPairsNew v-model=context />
            </Collapse>
          </div>

          <div class="well well-sm">
            <Input label="Порт PoD" :size=7 v-model="model.pod_port" :error=err.pod_port
            info="Порт для отсылки пакетов отключения сессий" @input="err.pod_port=null" />

            <Input label="Пароль PoD" :size=7 v-model="model.pod_password" :error=err.pod_password
            info="Пароль для пакетов отключения сессий" @input="err.pod_password=null" />
          </div>

          <div class="btn btn-primary" @click=submit><Icon name=save /> Сохранить</div>

        </form>
      </div>

  </div>
</template>

<script>
  import darsan from "darsan"
  import { notifyError, notifyHttpError, toMainCurrency } from "common"
  import { goto } from "navigation"

  import Input     from "common/visual/input/Input.vue"
  import Select    from "common/visual/input/Select.vue"
  import Select2   from "common/visual/input/Select2.vue"
  import Number    from "common/visual/input/Number.vue"
  import TextArea  from "common/visual/input/TextArea.vue"
  import SkelInput from "common/visual/SkelInput.vue"
  import ContextPairsNew from "nas/page/ContextPairsNew.vue"
  import Collapse from "common/visual/Collapse.vue"

  export default {
    name: "NasNew",
    components: { Input, Select, Select2, SkelInput, Number, TextArea, ContextPairsNew, Collapse },
    
    data () {
      return {
        err: {},
        model: {},
        lists: {},
        info: "Список контекстов:\npppoe, ipoe, static-ip, error-*",
        context: {},
      }
    },
    
    created () {
      this.ensure(["nas-types", "nas-ippools"])
      .then(() => this.clearModel())
    },
    
    methods: {
      clearModel () {
        for (name of Object.keys(this.model)) {
          this.model[name] = ""
        }

        this.model.type = "mikrotik"
        this.model.disable = "true"
        this.model.has_error_pools = "false"
      },

      validate() {
        const error = {} 

        for (const name of ["name", "ip"]) {
          if (!this.model[name]) {
            error[name] = "Введите значение"
          }
        }

        return error
      },
      
      submit () {
        const error = this.validate()

        if (Object.keys(error).length) {
          for (const k in error) {
            this.$set(this.err, k, error[k])
          }

          return
        }
        
        const data = Object.fromEntries(Object.entries(this.model).filter(([key,el]) => el !== null && el !== undefined))
        console.log(data);

        darsan.postJSON("", "client", "/nas/", data)
        
        .then( resp =>
        {
          for (const key in Object.keys(this.context))
          {
            const el = this.context[key]
            if (el.entity != "" && el.rad_pairs != "")
            {
              darsan.put("", "client", `/nas/${resp.entity}/context/${el.entity}`, {rad_pairs: el.rad_pairs})
            }
          }
        })

        .then(resp => {
          this.clearModel()
          goto("/nas")
        })

        .fail(xhr => {
          var resp = xhr.responseJSON || {};
          notifyError( resp.text_ru || resp.text || xhr.status + " " + xhr.statusText + ": " + xhr.responseText );

          if (resp.name) {
            this.$set(this.err, resp.name, resp.text_ru || resp.text)
          }
        });
      },

    },
    
    computed: {
      disableOptions () {
        return [
          { value: "true",  text: "Отключен" },
          { value: "false", text: "Активен"  }
        ]
      },

      yesNoOptions() {
        return [
          { value: "false", text: "Нет" },
          { value: "true",  text: "Да"  },
        ]
      },
      
      ipPoolOptions()
      {
        return this.$store.state.preload['/nas-ippools'].map(el => { return {text: el.name, value: el.id} })
      },

    },
  }
</script>
