<template>
<div>
  <h4>Групповое добавление комментария</h4>
  <div class="form-group">
    <label for="text">Текст:</label>
    <textarea class="form-control" id="text" v-model="text" />
  </div>
        
  <br>
  <button class="btn btn-secondary" @click=makeOp >Добавить заметку</button>
</div>
</template>

<script>
  import darsan from "darsan"

  export default {
    name: "gang-add-comment",
    
    props: {
      query: String,
    },
    
    data()
    {
      return {
        text: "",
      }
    },
    
    methods: {
      makeOp()
      {
        darsan
          .post("", 'client', '/gang/client/note', {
            text: this.text,
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))
          .fail(err => this.common.notifyError('Ошибка ' + err.status+ ": " + err.statusText))

        this.$emit("dismiss")
      }
    },
  }
</script>