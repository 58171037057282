<template>
<div>
  <h4>Групповая смена скидки</h4>
  <div class="form-group">
    <label for="reduction">Размер скидки (%):</label>
    <input class="form-control" v-model="reduction" type="number" min="1" max="100"/>
  </div>

  <br>
  <button class="btn btn-secondary" @click=makeOp >Установить скидку</button>
</div>
</template>

<script>
  import darsan from "darsan"

  export default {
    name: "gang-change-reduction",
    
    props: {
      query: String,
    },
    
    data()
    {
      return {
        reduction: "",
      }
    },
    
    methods: {
      makeOp()
      {
        darsan
          .post("", 'client', '/gang/client/reduction', {
            reduction: this.reduction,
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))
          .fail(err => this.common.notifyError('Ошибка ' + err.status+ ": " + err.statusText))

        this.$emit("dismiss")
      }
    },
  }
</script>