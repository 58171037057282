<template>
<div>
  <h4>Отключение снятий из-за проблем с Интернетом</h4>
<div class="alert alert-warning">
    Все выбранные пользователи будут отмечены как имеющие проблемы с Интернетом.
    Снятие денег с их счетов будет приостановлено, пока они снова не выйдут в сеть.
</div>

<br/>

<button class="btn btn-secondary" @click=makeOp >Остановить снятия</button>
</div>
</template>

<script>
  import darsan from "darsan"

  export default {
    name: "gang-mark-crash",
    
    props: {
      query: String,
    },
    
    data()
    {
      return {
      }
    },
    
    methods: {
      makeOp()
      {
        darsan
          .post("", 'client', '/gang/mark-crash', {
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))
          .fail(err => this.common.notifyError('Ошибка ' + err.status+ ": " + err.statusText))

        this.$emit("dismiss")
      }
    },
  }
</script>