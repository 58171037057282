import $ from "jquery"
import _ from "underscore"
import ReconnectingWebsocket from "reconnecting-websocket"

class announcer 
{
  constructor()
  {
    this.ws = null
    this.events = {}
    this.pendingPromises = []
  }
  
  whenReady()
  {
    return new Promise( (resolve, reject) =>
    {
      if (this.ws && this.ws.readyState==1)
      {
        return resolve()
      }
      
      this.pendingPromises.push(resolve)
    })
  }
  
  open(url)
  {
    this.ws = new ReconnectingWebsocket(url);
    const me = this

    this.ws.onopen = function()
    {
      me.pendingPromises.forEach( fun => fun() )
      me.pendingPromises = []
    }

    this.ws.onmessage = function(ev)
    {
      var message = JSON.parse(ev.data);
      var topic = message.topic;
    
      if (me.events[topic])
      {
        me.events[topic](message);
      }
      else if (me.events["*"])
      {
        me.events["*"](message);
      }
    }
  }
  
  close()
  {
    this.ws.close()
  }
 
  subscribe(topics, login)
  {
    topics = Array.isArray(topics) ? topics : [ topics ]
    const params = { type: "subscribe"};
    if (login) params.dest = login

    topics.forEach(el =>
    {
      params.topic = el
      this.ws.send(JSON.stringify(params))
    })
  }

  unsubscribe(topics)
  {
    topics = Array.isArray(topics) ? topics : [ topics ]
    topics.forEach(el =>
    {
      this.ws.send(JSON.stringify({ type: "unsubscribe", topic: el }));
    })
  }
  
  listen(handlers)
  {
    Object.keys(handlers).forEach( k =>
    {
      this.events[k] = handlers[k]
    })
  }
  
  unlisten(list)
  {
    list.forEach( e => delete this.events[e] )
  }
}

export default announcer
