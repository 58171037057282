import * as common from "common"
import moment from "moment"

export default {
  install (Vue, options) 
  {
    Vue.prototype.common = common
    Vue.prototype.moment = moment
    Vue.prototype.ensure = common.preloadEnsure
  }
}
