<template>
<div class="box">
 <strong>{{position}}</strong>
 <template v-if="caller.substr(0,1)=='0'"><a :href="`tel:${number}`" >{{niceNumber}}</a></template>
 <template v-else><a :href=uid :title=niceNumber>{{caller}}</a></template>
 <span>{{showSeconds()}}</span>
</div>
</template>

<script>

import {formatUptime} from "common.js"

export default {
  name: "Abon",
  
  props: {
    position: {
      type: [String, Number],
      required: true,
    },
    caller: {
      type: String,
      required: true,
    },
    started: {
      type: [String, Number],
      default: 0,
    },
    number: {
      type: String,
      default: "",
    },
  },
  
  data()
  {
    return {
      seconds: this.started,
      timer: null,
    }
  },
  
  mounted()
  {
    this.timer = setInterval(() => this.seconds++, 1000)
  },
  
  unmounted()
  {
    clearInterval(this.timer)
  },
  
  methods: {
    showSeconds(sec)
    {
      return formatUptime(this.seconds)
    }
  },
  
  computed: {
    uid()
    {
      const res = this.caller.match(/^(\w+)/)
      return res ? "/client/" + res[1] : this.caller
    },
    
    niceNumber()
    {
      const res = this.number.match(/(\d\d\d)(\d\d)(\d\d)$/)
      if (res)
      {
        return "(" + this.number.substr(0, res.index) + ") " + res[1] + "-" + res[2] + "-" + res[3]
      }
      else
      {
        return this.number
      }
    },
  },
}
</script>

<style scoped>
  .box {
    border: 1px solid gray;
    background: rgb(240,240,240);
    box-shadow: 5px 5px 10px 1px rgb(200,200,200);
    border-radius: 2px;
    margin-bottom: 0.5em;
    display:flex;
    justify-content: space-between;
    padding: 4px 1em;
  }
</style>
