define([
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',

    'common/visual/visual',
    'common',
    'text-loader!device/status/modules/pon_tree/deviceGraph.tpl'
  ],
  function($, _, Backbone, darsan, visual, common, layoutTemplate) {

    var prefix;
    var advData, lData = { type: {} };

    lData.period = [
      { id: 'default', name: 'По умолчанию' },
      { id: 'day', name: 'Сутки' },
      { id: 'week', name: 'Неделя' },
      { id: 'month', name: 'Месяц' },
      { id: 'year', name: 'Год' }
    ];

    return Object.create(visual).extend({
      title: 'Графики (ONU)',
      name: 'deviceGraph',
      icon: 'stats',
      $el: $('<div>'),
      create: function(el, opt) {
        var me = this;

	visual.create.apply(me, arguments);
	advData = opt.advData;

        this.fmodel = new Backbone.Model({ period: 'default' });
        this.fmodel.on('change:device change:onu change:mac change:port change:period refresh', function() {

	    var url = darsan.makeUrl(me.state.prefix, 'device', '/pon/' + me.fmodel.get('device') + '/onu/' + (me.fmodel.get('mac')||me.fmodel.get('onu')) + '/graph'), token = encodeURIComponent(localStorage.getItem('radix-token'));
	    var graph = [];

	    graph.push({
              url: url + '/power?period=' + me.fmodel.get('period') + '&darsan2=' + token,
              name: 'Уровни сигнала',
              id: 'power'
            });

	    graph.push({
              url: url + '/temp?period=' + me.fmodel.get('period') + '&darsan2=' + token,
              name: 'Температура',
              id: 'temp'
            });

	    graph.push({
              url: url + '/traffic?period=' + me.fmodel.get('period') + '&darsan2=' + token,
              name: 'Загрузка',
              id: 'traffic'
            });

	    graph.push({
              url: url + '/error?period=' + me.fmodel.get('period') + '&darsan2=' + token,
              name: 'Ошибки',
              id: 'error'
            });

	    graph.push({
              url: url + '/discard?period=' + me.fmodel.get('period') + '&darsan2=' + token,
              name: 'Сброшенные пакеты',
              id: 'discard'
            });

	    graph.push({
              url: url + '/unicast?period=' + me.fmodel.get('period') + '&darsan2=' + token,
              name: 'Unicast',
              id: 'unicast'
            });

	    graph.push({
              url: url + '/multicast?period=' + me.fmodel.get('period') + '&darsan2=' + token,
              name: 'Multicast',
              id: 'multicast'
            });

	    graph.push({
              url: url + '/broadcast?period=' + me.fmodel.get('period') + '&darsan2=' + token,
              name: 'Broadcast',
              id: 'broadcast'
            });

	    me.$el.html( _.template(layoutTemplate)({ fmodel: me.fmodel.toJSON(), lData: lData, graph: graph }));
	    return me;
        });

        me.$el.on('change', '[name="period"]', function(e) {
          me.fmodel.set({ period: $(e.currentTarget).val() });
        });

        me.$el.on('click', '#refresh', function(e) {
          me.fmodel.trigger('refresh');
        });

      },
      setState: function(state) {
        var me = this;

        me.state = JSON.parse(JSON.stringify(state));
	me.title = me.state.title;

	this.fmodel.set(state);
      }
    });
  }
);
