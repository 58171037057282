var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SearchTable',{ref:"table",attrs:{"name":"worker","title":"Работники","columns":_vm.columns,"dynamic":"","apiTopic":"darsan","apiPath":"/worker?paged=1","metaApiTopic":"darsan","metaApiPath":"/meta/worker/search","rowClass":_vm.rowClass,"quickSearch":"entity","toolbar":_vm.toolbar,"state":_vm.tableState},on:{"toolbar-click":_vm.toolbarClick},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(rec.title.join(""))+"\n  ")]}},{key:"avatar",fn:function(ref){
var rec = ref.rec;
return [_c('img',{staticClass:"avatar img-circle",attrs:{"src":_vm.avatarURL(rec)}})]}},{key:"entity",fn:function(ref){
var rec = ref.rec;
return [_c('a',{attrs:{"href":'/worker/'+rec.entity}},[_vm._v(_vm._s(rec.entity))])]}},{key:"disabled",fn:function(ref){
var rec = ref.rec;
return [(rec.disabled)?[_c('Icon',{attrs:{"name":"minus"}})]:[_vm._v("✓")]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }