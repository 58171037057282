<template>
<div>
  <h4>Групповой запуск акции</h4>
  <div class="alert alert-warning">
    У всех выбранных пользователей будет запущена новая акция.
  </div>

  <div class="form-group">
    <label for="action-select">Акция</label>
    <select class="form-control" v-model="action">
      <option v-for="item in $store.state.preload['/action']" :value=item.entity >{{item.display_name}}</option>
    </select>
  </div>
 
  <br/>

  <button class="btn btn-secondary" @click=makeOp >Запустить акцию</button>
</div>
</template>

<script>
  import darsan from "darsan"

  export default {
    name: "gang-start-action",
    
    props: {
      query: String,
    },
    
    data()
    {
      return {
        action: "",
      }
    },
    
    created()
    {
      this.ensure("action")
    },
    
    methods: {
      makeOp()
      {
        darsan
          .post("", 'client', '/gang/action', {
            action: this.action,
            query: this.query,
          })
          .done(res => this.common.notify(res, {}))
          .fail(err => this.common.notifyError('Ошибка ' + err.status+ ": " + err.statusText))

        this.$emit("dismiss")
      }
    },
  }
</script>