<template>
  <div>

      <div class="col-md-3 text-center">
        <span class="lead">Новая группа пользователей</span>
      </div>
      
      <div class="col-md-9">
        <form class="form-horizontal">
        
        <div class="well well-sm">

            <Number label="Номер группы" :size=7 v-model="model.gid" :error=err.gid @input="err.gid=null" />
            <Input label="Название" :size=7 v-model="model.name" :error=err.name @input="err.name=null" />
            <Input label="Описание" :size=7 v-model="model.descr" :error=err.descr @input="err.descr=null" />
            <Select label="Ответственный" :size=7 v-model="model.responsible" :error=err.responsible :options=activeWorkers @input="err.responsible=null" />
            <Select label="Тип застройки" :size=7 v-model="model.type_building" :error=err.type_building :options=typeBuilding @input="err.type_building=null"/>

        </div>

        <div class="btn btn-primary" @click=submit><Icon name=save /> Сохранить</div>

        </form>
      </div>

  </div>
</template>

<script>
  import darsan from "darsan"
  import { notifyError, notifyHttpError, toMainCurrency } from "common"
  import { goto } from "navigation"

  import Input     from "common/visual/input/Input.vue"
  import Select    from "common/visual/input/Select.vue"
  import Select2   from "common/visual/input/Select2.vue"
  import Number    from "common/visual/input/Number.vue"
  import TextArea  from "common/visual/input/TextArea.vue"
  import _ from "underscore"

  export default {
    name: "GroupNew",
    components: { Input, Select, Select2, Number, TextArea },
    
    data () {
      return {
        err: {},
        model: {},
      }
    },
    created () {
	this.ensure("worker");
	this.ensure("group-type-building");
    },
    methods: {
      clearModel () {
        for (name of Object.keys(this.model)) {
          this.model[name] = ""
        }
      },

      validate() {
        const error = {} 

        for (const name of ["gid", "name", "descr"]) {
          if (!this.model[name]) {
            error[name] = "Введите значение"
          }
        }

        return error
      },
      
      submit () {
        const error = this.validate()

        if (Object.keys(error).length) 
        {
          for (const k in error) {
            this.$set(this.err, k, error[k])
          }

          return
        }
        
        const data = Object.fromEntries(Object.entries(this.model).filter(([key,el]) => el))

        darsan.put("", "client", `/group2/${data.gid}`, data)
        .then(resp => {
          this.clearModel()
          goto("/group")
        })

        .fail(xhr => {
          var resp = xhr.responseJSON || {};
          notifyError( resp.text_ru || resp.text || xhr.status + " " + xhr.statusText + ": " + xhr.responseText );

          if (resp.name) {
            this.$set(this.err, resp.name, resp.text_ru || resp.text)
          }
        });
      },

    },
    computed: {
      activeWorkers()
      {
        const list = this.$store.state.preload['/worker']
          .filter(el => !el.disabled && !el.entity.match(/^\$/))
          .sort((a,b) => a.surname > b.surname ? 1 : a.surname < b.surname ? -1 : 0)
          .map(el => ({text: `${el.surname} ${el.name}`, value: el.entity }))
        list.unshift({text: "-", value: ""})
        return list
      },
      typeBuilding()
      {
        const list = _.clone(this.$store.state.preload['/group-type-building']);
        list.unshift({text: "-", value: ""})
        return list
      },
    },
  }
</script>
