<template>
  <div class="context-param">
	<div class="form-group" v-for="(rec,i) in model" style="padding-left:40px;">
          <Input :size=12 v-model=model[i].entity :extra=i :save=saveName class="col-sm-3"/>
	  <TextArea :size=12 :rows=3 v-model=model[i].rad_pairs :extra=i :save=savePairs class="col-sm-8"/>
	  <Icon name=trash @click=deleteContext(i) />
     </div>
  <Icon name=plus @click=addContext />
  </div>
</template>

<script>
import darsan from "darsan"
import Input from "common/visual/cooper/Input.vue"
import TextArea from "common/visual/cooper/TextArea.vue"

export default {
  name: "ContextPairs",
  
  components: {Input, TextArea},
  
  props: {
    id: [Number,String]
  },
  
  data()
  {
    return {
      model: [],
    }
  },
  
  methods: {
    async loadData()
    {
      this.model = await darsan.get("", "client", `/nas/${this.id}/context`)
    },
    
    async deleteContext(i)
    {
      await darsan.delete("", "client", `/nas/${this.id}/context/${this.model[i].entity}`)
      this.model.splice(i, 1)
    },
    
    addContext()
    {
      this.model.push({entity: "", rad_pairs: ""})
    },
    
    savePairs(val, old, i)
    {
      if (val=="" || this.model[i].entity=="")
      {
        return $.Deferred().resolve(1)
      }
      
      return darsan.put("", "client", `/nas/${this.id}/context/${this.model[i].entity}`, {rad_pairs: val})
    },
    
    saveName(val, old, i)
    {
      if (val=="" || this.model[i].rad_pairs=="")
      {
        return $.Deferred().resolve(1)
      }

      const promise = darsan.delete("", "client", `/nas/${this.id}/context/${old}`)

      return promise
        .then( () =>
          darsan.put("", "client", `/nas/${this.id}/context/${val}`, {rad_pairs: this.model[i].rad_pairs})
        )
    }
  },
  
  watch: {
    id: {
      immediate: true,
      handler: function()
      {
        this.loadData()
      }
    },
  },
  
}
</script>

<style scoped>
.context-param .row .glyphicon{
        align-self:center;
}

.context-param > .form-group > .form-group{
       display: initial !important;
}

.context-param label{
       display: none;
}
</style>
