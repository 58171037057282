<template>
  <l-map style="height: 92vh; width: 100%; z-index: 9" ref='myMap'
    :zoom="zoom"
    :center="center"
    :options="{preferCanvas: true}"
    @update:zoom="zoomUpdated"
    @update:center="centerUpdated"
    @update:bounds="boundsUpdated"
  >
    <l-tile-layer :url="url"></l-tile-layer>
    <l-control position="bottomright">
      <input type="checkbox" id="show-sessions" @change=toggleSessions v-model="showSessions">
      <label for="show-sessions"> Абоненты</label><br/>
      <input type="checkbox" id="show-devices" @change=toggleDevices v-model="showDevices">
      <label for="show-devices"> Устройства</label><br/>
    </l-control>
    <l-layer-group ref="sessions" :visible=showSessions />
    <l-layer-group ref="devices" :visible=showDevices />
  </l-map>
</template>
</template>

<script>
import L from 'leaflet'
import { LMap, LTileLayer, LControl, LLayerGroup } from 'vue2-leaflet'
import _ from "underscore"

import darsan from "darsan"

let coordinates
let timeOut
let oldZoom = 0

export default {
  name: 'SessionMap',
  components: {LMap, LTileLayer, LControl, LLayerGroup},
 
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: this.$store.state.userSettings.sessionMapZoom || 6,
      center: this.$store.state.userSettings.sessionMapCenter || [48.66919879926, 32.62939453125],
      bounds: null,
      saveCenter: null,
      showSessions: this.$store.state.userSettings.sessionMapShowSessions==null 
        ? true : this.$store.state.userSettings.sessionMapShowSessions,
      showDevices: this.$store.state.userSettings.sessionMapShowDevices || false,
    }
  },
  
  mounted()
  {
    this.saveCenter = _.debounce(function(center)
    {
      this.saveSetting("sessionMapCenter", [center.lat, center.lng])
    }, 1000);
  
    darsan.get("", "geo", "/srv/house/coordinates")
    .then(list =>
    {
      coordinates = Object.fromEntries(list.map(el => [el.entity, [+el.lat, +el.long]]))
      this.loadData()
    })
    
  },
  
  activated()
  {
    if (coordinates) this.loadData()
  },
  
  deactivated()
  {
    clearTimeout(timeOut)
  },

  methods: {
    zoomUpdated (zoom) 
    {
      if (oldZoom!=zoom)
      {
        oldZoom = zoom
        this.saveSetting("sessionMapZoom", zoom)
      }
    },
    centerUpdated(center) 
    {
      this.saveCenter(center)
    },
    
    boundsUpdated (bounds) {
      this.bounds = bounds;
    },
    
    loadData()
    {
      const me = this
      
      clearTimeout(timeOut)

      const promises = []
      promises.push(this.showSessions ? darsan.get("", "client", "/srv/session-map") : Promise.resolve() )
      
      if (this.showDevices)
      {
        promises.push(darsan.get("", "device", "/srv/device-map"))
        promises.push(darsan.get("", "device", "/srv/link-map"))
      }
      else
      {
        promises.push(Promise.resolve())
        promises.push(Promise.resolve())
      }

      Promise.all(promises).then(results =>
      {
        // Показываем сессии
        
        if (results[0])
        {
          results[0].forEach(el =>
          {
            const color = el.ppp ? "darkgreen" : el.ipoe ? "green" : "darkred"
            const coord = coordinates[el.location] ? coordinates[el.location].slice() : [0,0]
            coord[0] += (Math.random()-0.5)*2e-4
            coord[1] += (Math.random()-0.5)*2e-4
          
            L.circle(coord, {
              radius: 2, color, fill: true, fillOpacity: 1, interactive: false, bubblingMouseEvents: false
            }).addTo(this.$refs.sessions.mapObject)
          })
        }
        
        const devices = {}
        
        if (results[1])
        {
          // Показываем устройства
        
          results[1].forEach(el =>
          {
            const color = el.down ? "red" : "blue"
            const coord = coordinates[el.location] ? coordinates[el.location].slice() : [0,0]
          
            L.circleMarker(coord, {
              radius: 4, color, interactive: false, bubblingMouseEvents: false, opacity: 0.3,
            }).addTo(this.$refs.devices.mapObject)
            
            devices[el.device] = coord
          })
          
          // Показываем линки
          
          results[2].forEach(el =>
          {
            if (devices[el.device] && devices[el.device2])
            {
              L.polyline([ devices[el.device], devices[el.device2] ], 
                {color: 'blue', weight: 1, opacity: 0.4, bubblingMouseEvents: false})
                .addTo(this.$refs.devices.mapObject)
            }
          })
        }

      })
      
      timeOut = setTimeout(me.loadData, 60*1000)
    },
    
    saveSetting(name, value)
    {
      darsan.putJSON("","darsan","/worker/"+
          (this.$store.state.user.pretend||this.$store.state.user.login)+
          "/config/radix/"+name, value)
         .fail(res => notifyHttpError(res))
    },
    
    toggleDevices()
    {
      this.saveSetting("sessionMapShowDevices", this.showDevices)
      if (this.showDevices) this.loadData()
    },
    toggleSessions()
    {
      this.saveSetting("sessionMapShowSessions", this.showSessions)
      if (this.showSessions) this.loadData()
    },
 },

};
</script>
