<template>
<div>
  <Abon v-for="a in sortedList" :key=a.number :position=a.position :started=a.waited :caller=a.caller :number=a.number />
</div>
</template>

<script>
import _ from "underscore"
import Abon from "phone/page/Abon.vue"

export default {
  name: "AbonList",
  
  components: {Abon},
  
  props: {
    list: Array,
  },
  
  data()
  {
    return {
    }
  },
  
  methods: {
  },
  
  computed: {
    sortedList()
    {
      return _.sortBy(this.list, "position")
    }
  },
  
}
</script>