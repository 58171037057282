<template>
  <div class="row" style="padding-top: 1em">
      <div class="col-md-3 text-center">
        <ol class="breadcrumb">
          <li><span class="glyphicon glyphicon-th-list" aria-hidden="true"></span>&nbsp;&nbsp;<a href="/group">Группы пользователей</a></li>
          <li class="active">{{ model.name }}</li>
        </ol>

        <div class="btn-group-vertical" role="group" aria-label="...">
            <a class="btn btn-default" :href="'/history/group/'+this.state.id">История изменений</a>
            <a class="btn btn-default text-danger" @click=delGroup >
                <span class="glyphicon glyphicon-trash"></span> Удалить
            </a>
        </div>
      </div>

      <div class="col-md-9">
        <form class="form-horizontal">

          <div class="well well-sm">
            <Number label="Номер" :size=7 v-model="model.gid" name=gid apiTopic=client :apiPath=apiPath />
            <Input label="Название" :size=7 v-model="model.name" name=name apiTopic=client :apiPath=apiPath />
            <Input label="Описание" :size=7 v-model="model.descr" name=descr apiTopic=client :apiPath=apiPath />

            <Select label="Ответственный" :size=7 v-model="model.responsible" name=responsible apiTopic=client :apiPath=apiPath 
             :options=activeWorkers
            />

            <Select label="Тип застройки" :size=7 v-model="model.type_building" name=type_building apiTopic=client :apiPath=apiPath 
             :options=typeBuilding
            />

          </div>
       
        </form>
      </div>
  </div>
</template>

<script>

import _ from "underscore"

import Select from "common/visual/cooper/Select.vue"
import Select2 from "common/visual/cooper/Select2.vue"
import Input from "common/visual/cooper/Input.vue"
import Number from "common/visual/cooper/Number.vue"
import TextArea from "common/visual/cooper/TextArea.vue"
import SkelInput from "common/visual/SkelInput.vue"

import { notifyHttpError } from "common"
import darsan from "darsan"
import { goto } from "navigation"

export default {
  name: "GroupEditor",
  
  components: {Select, Input, SkelInput, Number, TextArea, Select2},

  props: {
    state: Object,
  },
  created () {
	this.ensure("worker");
	this.ensure("group-type-building");
  },
  data () {
    return {
      ready: false,
      model: {},
      err:   {},
    }
  },
  
  activated () {
    darsan.get("", "client", this.apiPath)
    .then((rec) => {
      this.ready = true 

      for (const name of Object.keys(rec)) 
      {
        this.$set(this.model, name, rec[name])
      }
      
    })
    .catch(xhr => notifyHttpError(xhr))
  },
  
  methods: {
    delGroup() {
      if (confirm("Вы уверены, что хотите удалить группу '" + this.model.name + "'?" )) {
        darsan.delete("", "client", "/group2/" + this.state.id)
        .done(() => goto("/group"))
        .fail(xhr => notifyHttpError(xhr))
      }
    },
  },
  
  computed: {
    apiPath () {
      return "/group2/" + this.state.id
    },

    activeWorkers()
    {
        const list = this.$store.state.preload['/worker']
          .filter(el => !el.disabled && !el.entity.match(/^\$/))
          .sort((a,b) => a.surname > b.surname ? 1 : a.surname < b.surname ? -1 : 0)
          .map(el => ({text: `${el.surname} ${el.name}`, value: el.entity }))
        list.unshift({text: "-", value: ""})
        return list
    },
    typeBuilding()
      {
        const list = _.clone(this.$store.state.preload['/group-type-building']);
        list.unshift({text: "-", value: ""})
        return list
      },
  },
  
}
</script>
