define([
  'jquery',
  'underscore',
  'backboneRadix',
  'darsan',
  'common',
  'common/dialog/dialog',
  'navigation',
  'permission',
  'common/visual/visual',
  'text-loader!geo/page/layout.tpl',
  'text-loader!geo/page/row.tpl',
  'text-loader!geo/page/edit.tpl',
  'text-loader!geo/page/pathRow.tpl',
  'text-loader!geo/page/geoCoder.tpl',
  'text-loader!geo/page/geoCoderRow.tpl',
  'text-loader!geo/page/mapEditor.tpl',
  '_lib/leaflet/leaflet_adv',
  'geo/page/main.css',
], function ($, _, Backbone, darsan, common, dialog, navigation, perm, visual, mainTpl, rowTpl, editTpl, pathRow, geoCoder, geoCoderRow, mapEditorTpl, leaflet) {
  'use strict';

    var url = darsan.makeUrl('', 'geo', '/location'), advData = {};
    function visicomParser(data){
	var name = [];
	if(data.properties.name) name.push(data.properties.name);
	if(data.properties.street) name.push(data.properties.street_type + ' ' + data.properties.street);
	if(data.properties.settlement) name.push( data.properties.settlement_type + ' ' +data.properties.settlement);
	if(data.properties.country) name.push(data.properties.country);
	return { name: name.join(', '), geometry: data.geo_centroid };
    }

    //Список геокодеров карт
    advData.map_geocoder = [{ id: 'osm', name: 'OSM',
	    func: function(search){
		return $.ajax({ url: 'https://nominatim.openstreetmap.org/search?q='+encodeURIComponent(search)+'&format=json' }).then(function(data){
			return _.map(data, function(v){ return { name: v.display_name, geometry: { type: 'Point', coordinates: [ v.lon, v.lat ]}} });
		});
	}
    }];

    if( config.geo && config.geo.visicom ){
	advData.map_geocoder.push({ 
	    id: 'visicom', 
	    name: 'Vsicom',
	    func: function(search){
	        return $.ajax({ url: 'https://api.visicom.ua/data-api/4.0/ru/geocode.json?text='+encodeURIComponent(search)+'&key='+config.geo.visicom.key }).then(function(data){
		    if( !data ) return;
		    if( data.type == 'Feature' ) return [ visicomParser(data) ];
		    if( data.type == 'FeatureCollection' ) return _.map(data.features, visicomParser);
		})
	    }
	});
    }



	var model = Backbone.Model.extend({
	    idAttribute: 'entity',
	    urlRoot: url,
 	    initialize: function(){
    	 	this.on('error', function( a, e ){
     			common.notifyError(e.responseText);
      		});
    	}
	});

	var collection = Backbone.Collection.extend({
		model: model,
		comparator: function(model){
			return model.get("name");
		}
	});

	var l = leaflet({ center: [0,0], zoom: 10 });
	var map = l.map, marker, polygon;
	var $el = $('<div>', { html: mainTpl }), $elm = $('<div>', { css: { width: '60vw', height: '60vh' }}).append(l.el);

	var map_config = JSON.parse(localStorage.getItem('map_config')) || { lat: 48.0, lng: 37.47, zoom: 10 };
	map.setView([ map_config.lat, map_config.lng ], map_config.zoom);

	var addMarkerControl = L.Control.extend({
		options: {
			position: 'topright' 
		},
		onAdd: function (map) {
		    var container = L.DomUtil.create('div', 'leaflet-control leaflet-control-layers');

		    container.style.backgroundColor = 'white';
		    container.style['text-align'] = 'center';
		    container.setAttribute("title", "Добавить маркер");
			container.innerHTML = '<a href="#" id="addmarker"><i class="fa fa-map-marker"></i></a>';
		    return container;
		}
	});

	var addPolyControl = L.Control.extend({
		options: {
			position: 'topright' 
		},
		onAdd: function (map) {
		    var container = L.DomUtil.create('div', 'leaflet-control leaflet-control-layers');

		    container.style.backgroundColor = 'white';
		    container.style['text-align'] = 'center';
		    container.setAttribute("title", "Добавить полигон");
			container.innerHTML = '<a href="#" id="addpoly"><i class="fa fa-map"></i></a>';
		    return container;
		}
	});

	var geoCoderControl = L.Control.extend({
		options: {
			position: 'topright' 
		},
		onAdd: function (map) {
		    var container = L.DomUtil.create('div', 'leaflet-control leaflet-control-layers');

		    container.style.backgroundColor = 'white';
		    container.style['text-align'] = 'center';
		    container.setAttribute("title", "Служба геокодирования");
			container.innerHTML = '<a href="#" id="geocoder"><i class="fa fa-map-signs"></i></a>';
		    return container;
		}
	});


	var removeMarkerControl = L.Control.extend({
		options: {
			position: 'topright' 
		},
		onAdd: function (map) {
		    var container = L.DomUtil.create('div', 'leaflet-control leaflet-control-layers');

		    container.style.backgroundColor = 'white';
		    container.style['text-align'] = 'center';
		    container.setAttribute("title", "Очистить карту");
			container.innerHTML = '<a href="#" id="clear"><i class="fa fa-trash"></i></a>';
		    return container;
		}
	});

	map.addControl(new addMarkerControl());
	map.addControl(new addPolyControl());
	map.addControl(new geoCoderControl());
	map.addControl(new removeMarkerControl());

	//Действия карты
	map.on('zoomend', function(e){
		_.extend(map_config,{ zoom:  map.getZoom() });
		localStorage.setItem('map_config', JSON.stringify(map_config));
	});
	map.on('dragend', function(e){
		_.extend(map_config, map.getCenter());
		localStorage.setItem('map_config', JSON.stringify(map_config));
   });

    var viewGeoCoderRow = Backbone.View.extend({
	tagName: 'tr',
	template: _.template(geoCoderRow),
	render: function(){
	    this.$el.html( this.template({ model: this.model.toJSON(), advData: advData }));
	    return this;
	},
	toSelect: function(){
	    this.model.trigger('select', this.model);
	},
	events: {
	    'click': 'toSelect',
	}
    });

    var viewGeoCoder = Backbone.View.extend({
	template: _.template(geoCoder),
	initialize: function(){
	    this.model.set({ geocoder: localStorage.getItem('map_geocoder')||(_.first(advData.map_geocoder)||{}).id });
	    this.$el.html( this.template({ model: this.model.toJSON(), advData: advData }));
	    this.collection.on('reset', this.render, this);
	},
	render: function(){
	    if( !this.oldView ) this.oldView = {};

	   //Подключаем виевы
	    var currView = {}, frag = document.createDocumentFragment();
	    this.collection.forEach(function(model){

		var view = this.oldView[model.cid] || new viewGeoCoderRow({ model: model }).render();
		currView[model.cid] = view;
		frag.appendChild(view.el)

	    }, this);

	    //Получаем el
	    var el = this.$('table > tbody');

	    //Вставляем контент
	    el.html( frag );

	    //Уничтожаем неиспользуемые
	    _.each(this.oldView, function(v,k){ if( !currView[k] ) v.remove(); }, this);

	    this.oldView = currView;

	    return this;
	},
	toSearch: function(e){
	    e.preventDefault();
	    var me = this;

	    var gc = _.findWhere(advData.map_geocoder, { id: this.model.get('geocoder') });
	    if( !gc ) return;

	    if(!this.model.get('fullname')) return console.error("Query is emtpty");
	    gc.func(this.model.get('fullname')).done(function(data){ me.collection.reset(data) });
	},
	toChange: function(e){
	    var me = this;

	    var data = {}, el = $(e.target);
	    data[el.attr('name')] = ( el.attr('type') == 'checkbox' ) ? +el.is(':checked') : el.val();

	    if(data['geocoder']) localStorage.setItem('map_geocoder', data['geocoder']);
	    this.model.set(data);
	},
	events:{
		'change form#geocoder': 'toChange',
		'submit form#geocoder': 'toSearch',
	}
    });

    var viewEdit = Backbone.View.extend({
		template: _.template(editTpl),
		render: function(template) {
			/*Очищаем предыдущие события*/
			map.off('contextmenu');
			map.off('click');
			map.getContainer().style.cursor = '';

			/*Очищаем маркеры и полигоны*/
			if( marker ) map.removeLayer( marker );
			if( polygon ) map.removeLayer( polygon );

			var template = template || this.template;

			this.$el.html( template({ model: this.model.toJSON(), advData: advData }));

			this.$('#map').append( $elm );
			window.dispatchEvent( new Event('resize') );

			var geo = this.model.get('geo');
			if( geo ){
				if( geo.type == 'Point' && geo.coordinates ){
					this.renderMarker(L.latLng( geo.coordinates[1], geo.coordinates[0] ));
				}
				if( geo.type == 'Polygon' && geo.coordinates ){
					var coordinates = _.map(geo.coordinates[0], function(v){ return L.latLng( v[1], v[0] ); });
					this.renderPolygon( coordinates );
				}
			}
			return this;	
		},
		renderMarker: function(latLng){
			var me = this;

			marker = new L.Marker(latLng, {draggable:true}).addTo(map);
			marker.on('dragend', function(e){
				var data = { geo: marker.toGeoJSON().geometry };
				if( me.model.isNew() ){
			 	   me.model.set( data );
				}else{
			 	   me.model.save( data, { patch: true });
				}
			});
			map.setView( latLng );
		},
		renderPolygon: function(latLngs){
			var me = this;
			var icon = new L.DivIcon({ iconSize: new L.Point(10, 10), iconAnchor: [5, 5] });

			polygon = new L.polygon([], {color: 'red'}).addTo(map);
			polygon.points = L.layerGroup([]).addTo(map);
			
			polygon.addLatLng = function(e, o){
				L.Polyline.prototype.addLatLng.call(polygon, e, o);

				var id = polygon.getLatLngs()[0].length - 1;
				var marker = new L.Marker(e, { draggable: true, icon: icon });

				marker.on('drag', function(e){
					var array = polygon.getLatLngs()[0];
					array[id] = e.latlng;
					polygon.setLatLngs( array );				
				});

				marker.on('dragend', function(){
					var data = { geo: polygon.toGeoJSON().geometry };
					if( me.model.isNew() ){
				 	   me.model.set( data );
					}else{
			 		   me.model.save( data, { patch: true });
					}
				});
				polygon.points.addLayer(marker);
			};

			polygon.on('remove', function(e){
				polygon.points.clearLayers();
			});

			if( latLngs ){
			    _.map(latLngs.slice(0,-1), function(v){ polygon.addLatLng(v); });
			    map.setView( polygon.getBounds().getCenter() );
			}
		},
		toAddMarker: function(e){
			e.preventDefault();
			var me = this;

			this.toClear(e);
			map.getContainer().style.cursor = 'crosshair';
			
			map.once('click', function(e){
				me.renderMarker( e.latlng );
				map.getContainer().style.cursor = '';

				var data = { geo: marker.toGeoJSON().geometry };
				if( me.model.isNew() ){
			 	   me.model.set( data );

				}else{
			 	   me.model.save( data, { patch: true });
				}
			});		
		},
		toAddPoly: function(e){
			e.preventDefault();
			var me = this;

			this.toClear(e);
			me.renderPolygon();

			function addPoint(e){
				polygon.addLatLng(e.latlng); 

				var data = { geo: polygon.toGeoJSON().geometry };
				if( me.model.isNew() ){
			 	   me.model.set( data );
				}else{
			 	   me.model.save( data, { patch: true });
				}
			}

			map.off('click', addPoint);
			map.on('click', addPoint);

			map.getContainer().style.cursor = 'crosshair';

			map.once('contextmenu', function(){
			    map.off('click', addPoint);
			    map.getContainer().style.cursor = '';
			});
		},
		toClear: function(e){
			e.preventDefault();
			if( marker ) map.removeLayer( marker );
			if( polygon ) map.removeLayer( polygon );

			this.model.set('geo', {});

			if( !this.model.isNew() ){
		 	   this.model.save(null, { patch: true });
			}
		},
		toGeoCoder: function(e){
			e.preventDefault();
			var me = this;

			var model = this.model.clone(), collection = new Backbone.Collection();
			if( config.geo && config.geo.root ) model.set({ fullname: config.geo.root + ', ' + model.get('fullname') });

			dialog.show('Поиск координат по названию',  new viewGeoCoder({ model: model, collection: collection }).render().el, { id: 'geocoder_d',  width: '850px', height: 'auto' });

			//Событие установки координат
			collection.once('select', function(model){ 

			    if( me.model.isNew() ){
				   me.model.set({ geo: model.get('geometry') });
			    }else{
				   me.model.save({ geo: model.get('geometry') }, { patch: true });
			    }

			    if( marker ) map.removeLayer( marker );
			    if( polygon ) map.removeLayer( polygon );

			    let v = me.model.get('geo').coordinates;
			    me.renderMarker( L.latLng( v[1], v[0] ) );

			    dialog.close('geocoder_d');
			});
		},
		toChange: function(e){
			var me = this;

		    var data = {}, el = $(e.target);
		    data[el.attr('name')] = ( el.attr('type') == 'checkbox' ) ? +el.is(':checked') : el.val();

			if( this.model.isNew() ){
		 	   this.model.set( data );
			}else{
		 	   this.model.save( data, { patch: true, success: function(){
	 	   		me.animateSuccess(el);
		 	   }, error: function(){
	 	   		me.animateFail(el);
		 	   }});

			}
		},
		toAdd: function(e){
			e.preventDefault();
			var me = this;
			this.model.save(null, { success: function(){
			    me.collection.add( me.model );
			    dialog.close('geo');
			}});
		},
	   animate: function(el, _class){
	      	el.removeClass(_class).addClass(_class).delay(1000).queue(function(){
          		$(this).removeClass(_class).dequeue();
      		});
    	},
    	animateSuccess: function(el){
			return this.animate(el,"animate_success");
		},
   		animateFail: function(el){
			return this.animate(el,"animate_fail");
		},
		events:{
			'click #addmarker': 'toAddMarker',
			'click #addpoly': 'toAddPoly',
			'click #clear': 'toClear',
			'click #geocoder': 'toGeoCoder',
			'change form#edit': 'toChange',
			'submit form#edit': 'toAdd',
		}
	});

	var viewRow = Backbone.View.extend({
		className: 'geo-row',
		template: _.template(rowTpl),
		initialize: function(){
			this.listenTo(this.model, 'change', this.render);
		},
		render: function(){
			this.$el.html( this.template({ model: this.model.toJSON(), advData: advData }));
			return this;
		},
		toEdit: function(e){
			e.preventDefault();
			dialog.show('Изменить локацию',  new viewEdit({ collection: this.collection, model: this.model }).render().el, { id: 'geo',  width: 'auto' });
			window.dispatchEvent( new Event('resize') );
		},
		toDelete: function(e){
			var $el = $(e.currentTarget);
			if( $el.attr('entity') != this.model.id ) return;
			e.preventDefault();

			if( !confirm("Удалить объект '"+this.model.get('name')+"'?") ) return;
			this.model.destroy({ wait: true, success: function(){
			    dialog.close('geo');
			}});
		},
		toMergeFrom: function(e){
			e.preventDefault();

			var collection = this.model.collection;
			collection.map(function(m){

			    if(m.cid == this.model.cid) return;
			    m.set({ merge_from: this.model.get('entity') });

			}, this);
		},
		toMergeTo: function(e){
			e.preventDefault();
			var me = this;
			var collection = this.model.collection;

			var from = this.model.get('merge_from'), to = this.model.get('entity');

			var from_name = this.model.get('name'), to_name = collection.get(from).get('name');
			collection.map(function(m){ m.unset('merge_from'); }, me);

			if( !confirm('Перенести всех потомков из "'+from_name+'" в "'+to_name+'"? Данное действие нельзя отменить.') ){ 
			    collection.fetch();
			    return;
			}

			darsan.post('', 'geo', '/merge/'+from+'/to/'+to).then(function(data){ 
			    common.notify('Птомки из "'+from_name+'" успешно перенесены в "'+to_name+'".');
			    collection.fetch();
			}).fail(function(e){
			    common.notifyError(e.responseText);
			});
		},
		events: {
			'click a.mergeTo': 'toMergeTo',
			'click a.mergeFrom': 'toMergeFrom',
			'click a.delete': 'toDelete',
			'click a.edit': 'toEdit'
		}
	});

	var viewColl = Backbone.View.extend({
		className: 'geo_data',
		initialize: function(){
			this.collection.on('sync remove', this.render, this);
		},
		render: function(){

		    if( !this.oldView ) this.oldView = {};

	 	  //Подключаем виевы
		    var currView = {}, frag = document.createDocumentFragment();
	   	    this.collection.forEach(function(model){

			var view = this.oldView[model.cid] || new viewRow({ model: model }).render();
			currView[model.cid] = view;
			frag.appendChild(view.el)

	    	    }, this);

		//Получаем el
		var el = this.el;

		//Вставляем контент
		this.$el.html( frag );

	    	//Уничтожаем неиспользуемые
		_.each(this.oldView, function(v,k){ if( !currView[k] ) v.remove(); }, this);

		this.oldView = currView;
		return this;
		},
	});

  var cache = {};
  var uniq = 0;

  var pathModel = Backbone.Model.extend({
	initialize: function(){
	    this.order = uniq++;
	}
  });

  var pathCollection = Backbone.Collection.extend({
  	model: pathModel
  });

  var pathView = Backbone.View.extend({
	template: _.template( pathRow ),
 	initialize: function(){
		this.collection.on('change remove add', this.render, this);
	},
	render: function(){
		var array  = [], k = 0;
		this.collection.forEach(function(model){
			++k;
			array.push( this.template({ model: model.toJSON(), advData: advData, param: { last: this.collection.length == k } }));
		}, this);
		this.$('#path').html( array );
	},
	toChildren: function(e){
		e.preventDefault();

		var me = this;
  		var id = $(e.currentTarget).attr('entity');

		var path = [], model = this.collection.get( id );
		if( model ){
			this.collection.forEach(function( model2 ){
				if( model2.order <= model.order ){
					path.push(model2.get('id'));
				}		
			}, this);
		}else{
			path = this.collection.pluck('id');
			path.push( id );
		}

		navigation.changeState({ _page: "geo", _rest: '/'+path.join('/') });
 	},
	toAdd: function(e){
		e.preventDefault();
		var id = $(e.currentTarget).attr('entity');

		var mod = new model({ class: _.first( advData.class ).entity , parent: id });
		dialog.show('Новая локация',  new viewEdit({ collection: cache[id].collection, model: mod }).render().el, { id: 'geo', width: 'auto' });
	},
	toEdit: function(e){
		e.preventDefault();
		var id = $(e.currentTarget).attr('entity');

		var pmodel = getModelFromCache( id );
	    dialog.show('Редактирование локации',  new viewEdit({ collection: pmodel.collection, model: pmodel }).render().el, { id: 'geo', width: 'auto' });
	},
  	events: {
		'click a.add_p': 'toAdd',
		'click a.edit_p': 'toEdit',
  		'click a#children' : 'toChildren'
  	}
  });


  function getModelFromCache( id ){
	var m;
  	_.some(cache, function(v){
		m = v.collection.get( id );
		if( m ) return 1;
	});
	return m;
  }


  return Object.create(visual).extend({
    name: "geo",
    title: "География (редактор)",
    icon: "comment",

    create: function (el, opt) {
      var me = this;
      visual.create.apply(me, arguments);
	  me.$el.html( $el );

	  var coll = new pathCollection();
	  this.view =  new pathView({ collection: coll, el: $el });

	  return darsan.get('', 'geo', '/meta/location-type').then(function(data){ 
		advData.class = data
	  });

    },

    editCoordinates: function (options) {
    	var me = this;

    	var locationModel = new model({ entity: options.locationId });
    	locationModel.fetch({
    		success: () => {
    			dialog.show(
    				'Редактирование координат',
    				new viewEdit({ collection: null, model: locationModel}).render(_.template(mapEditorTpl)).el,
    				{ id: 'geo',   onClose: options.onClose }
    			);
    		}
    	})
    },

    setState: function (state) {
		var me = this;
		me.state = state;

		var path = _.compact(state._rest.split('/'));
		if( path[0] != 'root' ){

			var lModel = new model({ entity: path[0] });
			lModel.fetch({ success: function(){
			    dialog.show('Редактирование локации',  new viewEdit({ collection: null, model: lModel }).render().el, { id: 'geo',  width: 'auto' });
			    window.dispatchEvent( new Event('resize') );
			}});

			path = ['root']; 
		}

		this.view.collection.reset();

		 var array = [];
		 _.forEach(path, function( id ){
			if( !id ) return;

			var param = { id: id };
			if( id == 'root' ){
				param.url = url + '/root';
				param.name = 'Root';
			}else{ 
				param.url = url + '/' + id + '/children';

				//Имена из кеша
				var m2 = getModelFromCache( id );
				if( m2 ){
				    param.name = m2.get('name');
				    param.class = m2.get('class');
				    param.class_display_name = m2.get('class_display_name');
				}
			}

			var model = new pathModel( param );
			this.view.collection.add( model );

			if( !cache[id] ){
			    var coll = new collection(), view = new viewColl({ collection: coll });
			    coll.url = model.get('url');
			    coll.parent = model.get('id');
			    cache[id] = { collection: coll, view: view };

			    var d = $.Deferred();
			    array.push( d );
			    coll.fetch({ success: d.resolve });
			}
	 	 }, this);

		  //Имена из запроса
		  $.when.apply($, array).then(function(){

			me.view.collection.forEach(function(model){
				if(model.get('name')) return;

				var m2 = getModelFromCache(model.get('id'));
				if( m2 ){
				    model.set({ name: m2.get('name'), class: m2.get('class'), class_display_name: m2.get('class_display_name') });
				}
			});
		  }).then(function(){

			//Флаг локаций вложенных в зону ответвенности
			var za;
			me.view.collection.forEach(function(model){

				var id = model.get('id');
				var coll = cache[id].collection;

				//Если встречаем в пути Зону ответвенности, то все дальнейшие обьекты должны быть получены с сервера с ограничением по этой зоне
				if( model.get('class')== 'зона ответственности' ){

				    za = id;
				    coll.url = darsan.makeUrl('', 'geo', '/srv/polygon/'+id+'/street');
				    coll.reset();
				    coll.fetch();

				}else if( za ){

				    coll.reset();
				    coll.fetch({ data: { within: za } });
				    delete cache[id];
				}
			});

		  }); 

		  this.view.$('#main').children().detach();
		  this.view.$('#main').append( cache[_.last(path)].view.el );
	}
  });
});
