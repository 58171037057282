<template>
<div class="box" :title=opTitle v-bind:class=opClass >
  <img :src=opAvatar class="img-circle" style="height: 45px; width: 45px; margin-right: 10px" />
  <template v-if="caller">
    <!--Icon :name=statusIcon style="font-size: large" /-->
    <template v-if="caller.substr(0,1)=='0'"><a :href="`tel:${number}`" >{{niceNumber}}</a></template>
    <template v-else><a :href=uid :title=niceNumber>{{caller}}</a></template>
    <div style="margin-left: 1em; margin-right: 1em">{{showSeconds()}}</div>
  </template>
  <template v-else><div class="calls">{{opCallsTaken}}</div></template>
</div>

</template>

<script>

import {formatUptime} from "common.js"
import moment from "moment"

export default {
  name: "Operator",
  
  props:
  {
    worker: {
      type: String,
      required: true,
    },
    sip: {
      type: [Number,String],
      required: true,
    },
    status: {
      type: String,
    },
    calls_taken: {
      type: Number,
      default: 0,
    },
    number: {
      type: String,
      default: null,
    },
    caller: {
      type: String,
      default: null,
    },
    started: {
      type: Number,
      default: 0,
    },
    last_call: {
      type: Number,
      default: null,
    },
  },
  
  data()
  {
    return {
      seconds: this.started,
      timer: null,
    }
  },

  mounted()
  {
    this.timer = setInterval(() => this.seconds++, 1000)
  },
              
  unmounted()
  {
    clearInterval(this.timer)
  },  
  
  methods: {
    showSeconds(sec)
    {
      return formatUptime(this.seconds)
    },
  },
  
  computed: {
    
    opTitle()
    {
      let title = [this.worker, `SIP: ${this.sip}`]
      
      if (this.last_call && this.last_call<moment().unix()) 
      {
        title.push(`Последний звонок: ${moment(this.last_call*1000).locale("ru").fromNow()}`);
      }
      
      return title.join("\n")
    },
    
    opClass()
    {
      const cl = {
        [this.status]: true,
      }
      if (!this.caller) cl["with-calls"] = true
      return cl
    },
    
    uid()
    {
      const res = this.caller.match(/^(\w+)/)
      return res ? "/client/" + res[1] : this.caller
    },
    
    niceNumber()
    {
      const res = this.number.match(/(\d\d\d)(\d\d)(\d\d)$/)
      if (res)
      {
        return "(" + this.number.substr(0, res.index) + ") " + res[1] + "-" + res[2] + "-" + res[3]
      }
      else
      {
        return this.number
      }
    },
    
    statusIcon()
    {
      if (this.status=="ringing")
        return "fa-volume-control-phone"
      else if (this.status=="in-use")
        return "earphone"
      else
        return "phone-alt"
    },
    
    opAvatar()
    {
      return `${API}/avatar/${this.worker}`
    },
    
    opCallsTaken()
    {
      return this.calls_taken ? this.calls_taken : ""
    },
    
  },
}
</script>

<style scoped>
.box {
  margin-bottom: 1em;
  margin-right: 1.5em;
  box-shadow: 5px 5px 10px 1px rgb(200,200,200);
  padding: 3px 3px 3px 8px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 22em;
}

.box.with-calls {
 justify-content: left !important;
}

.calls {
  font-size: x-large;
  margin-left: 0.5em;
}
.in-use {
  border: 1px solid blue;
  background: #d6f5f5;
}
.ringing {
  border: 1px solid orange;
  background: #fff5e6;
}
.not-in-use {
  border: 1px solid green;
  background: #eeffcc;
}
</style>