var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SearchTable',{ref:"table",attrs:{"name":"client","title":"Абоненты","columns":_vm.columns,"dynamic":"","apiTopic":"client","apiPath":"/client?paged=1","metaApiTopic":"client","metaApiPath":"/meta/client/search","rowClass":_vm.rowClass,"cellClass":_vm.cellClass,"toolbar":_vm.toolbar,"state":_vm.modus.params},on:{"toolbar-click":_vm.toolbarClick},scopedSlots:_vm._u([{key:"Operations",fn:function(){return [(_vm.gangComp)?_c('div',{staticClass:"gang bg-light"},[_c('span',{staticClass:"close",on:{"click":_vm.closeGang}},[_c('Icon',{attrs:{"name":"fa-close"}})],1),_vm._v(" "),_c('div',{staticClass:"alert alert-danger",staticStyle:{"width":"98%"}},[_vm._v("Внимание! Результат групповой операции нельзя будет отменить!")]),_vm._v(" "),_c('keep-alive',[_c(_vm.gangComp,{tag:"component",attrs:{"query":_vm.$refs.table.currentQuery()},on:{"dismiss":_vm.closeGang}})],1)],1):_vm._e()]},proxy:true},{key:"online",fn:function(ref){
var rec = ref.rec;
return [(rec.online==1)?[_c('Icon',{staticClass:"text-success",attrs:{"name":"star"}})]:[_vm._v(" ")]]}},{key:"entity",fn:function(ref){
var rec = ref.rec;
return [_c('a',{attrs:{"href":'/client/'+rec.entity}},[_vm._v(_vm._s(rec.entity))])]}},{key:"delete",fn:function(ref){
var rec = ref.rec;
return [_c('Icon',{attrs:{"name":"trash"},nativeOn:{"click":function($event){return _vm.deleteClient(rec.entity)}}})]}},{key:"group",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(rec.group)+": "+_vm._s(rec.group_name)+"\n  ")]}},{key:"location_name",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(rec.location_name)),(rec.flat)?[_vm._v(", кв. "+_vm._s(rec.flat))]:_vm._e()]}},{key:"phone",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(rec.phone)+"\n    "),(rec.phone2)?[_c('br'),_vm._v(_vm._s(rec.phone2))]:_vm._e(),_vm._v(" "),(rec.phone3)?[_c('br'),_vm._v(_vm._s(rec.phone3))]:_vm._e()]}},{key:"disabled",fn:function(ref){
var rec = ref.rec;
return [(rec.disabled==1)?[_c('Icon',{attrs:{"name":"minus"}})]:[_vm._v("✓")]]}},{key:"balance",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(_vm.common.formatCurrency2(_vm.$store.state.preload["/currency"], rec.balance))+"\n  ")]}},{key:"deposit",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(_vm.common.formatCurrency2(_vm.$store.state.preload["/currency"], rec.deposit))+"\n  ")]}},{key:"credit",fn:function(ref){
var rec = ref.rec;
return [_vm._v("\n    "+_vm._s(_vm.common.formatCurrency2(_vm.$store.state.preload["/currency"], rec.credit))+"\n  ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }