import _ from  'underscore'
import * as common from 'common'
import darsan from "darsan"
import init from 'init'
import login from "login"

import mitt from 'mitt'

import bootstrap from "bootstrap"
import 'bootstrap-daterangepicker/daterangepicker.css'
import '_lib/bootstrap-treeview/bootstrap-treeview.min.css'

import "font-awesome/css/font-awesome.css"

import UniqueId from 'vue-unique-id';


import $ from "jquery"
import "jquery-ui"
import "jquery-ui/ui/widgets/draggable"
import "jquery-ui/ui/widgets/droppable"
import "jquery-ui/ui/widgets/resizable"
import "jquery-ui/ui/widgets/sortable"
import "jquery-ui/ui/widgets/dialog"
// должно идти после Bootstrap, иначе не работает tooltip
import "jquery-ui/ui/widgets/tooltip"

import "jquery-ui/themes/base/theme.css"
import "jquery-ui/themes/base/base.css"

import "select2"
import 'select2/select2.css'
import 'select2/select2-bootstrap.css'

import "../public/css/main.css"
import 'leaflet/dist/leaflet.css'

import {notifyError} from "common"

import Vue from "vue/dist/vue.esm.js"
Vue.config.ignoredElements = ["font"]
Vue.config.productionTip = true
Vue.config.errorHandler = vueError
window.onerror = (message, source, line, column, error) => 
{
    notifyError(message)
}

import store from "store"

import Common from "CommonPlugin.js"

import App from "App.vue"
import Panel from "common/visual/Panel.vue"
import SearchTable from "common/visual/SearchTable.vue"
import Table from "common/visual/Table.vue"
import Icon from "common/visual/Icon.vue"

////////////////////////////////////////////////////////////////

fetch(API+'/config')
  .then(response => response.json())
  .catch(err => alert("Не могу получить конфигурацию: " + err.stack))
  .then(conf => 
  {
    config = conf
    main()
  })
  .catch(err => alert("Не могу инициализировать конфигурацию: " + err.stack));

//////////////////////////////////////////////


function main()
{
  init.initDarsan(darsan)
  login.init()
  
  Vue.component("Panel", Panel)
  Vue.component("SearchTable", SearchTable)
  Vue.component("Table", Table)
  Vue.component("Icon", Icon)
   
  Vue.use(Common)
  Vue.use(UniqueId, {uidProperty: "uniqueId", uidPrefix: "uniq"});

  const vue =  new Vue({                                          
    //router,                                          
    store,                                           
    render: h => h(App),                             
  })

  if (!config.additional_client_domains) config.additional_client_domains = []  
  
  config.additional_client_domains.forEach(el =>
  {
      el.range = el.range.split("-")
  })
  
  init.initRadix(vue)

  vue.$store.commit("setConfig", config)
  vue.$mount('#app')
  
  // сменим иконку
  $("#favicon").attr("href",`/img/favicon/${config.domain}.ico`);
  
  // затребовать токен дарсана. Сам по себе тут он нам не нужен, но это вызовет при необходимости первую загрузку 
  // и появится окно логина
  darsan.promiseUserInfo()
    .done( rec => init.initUser(vue, rec) )
    .fail(darsan.err);
}

function vueError(err, vm, info)
{
  if (!vm) vm = {name: "???"}
  notifyError(vm.name+": "+info+"<br/>"+err)
  console.log(vm.name, err, info)
}

/////////////////////////////

export const eventBus = mitt()

