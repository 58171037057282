define(
  [
    'jquery',
    'underscore',
    'backboneRadix',
    'darsan',

    'common/visual/visual',
    'common',

    'text-loader!device/status/modules/graph_device/main.tpl',
  ],
  function($, _, Backbone, darsan, visual, common, mainTemplate) {
    var advData,
      lData = { type: {} };

    lData.period = [
      { id: 'default', name: 'По умолчанию' },
      { id: 'day', name: 'Сутки' },
      { id: 'week', name: 'Неделя' },
      { id: 'month', name: 'Месяц' },
      { id: 'month3', name: '3 Месяца' }
    ];

    lData.type.switch = [{ id: 'cpu', name: 'Загрузка ЦП' }];
    lData.type.pon = [{ id: 'cpu', name: 'Загрузка ЦП' }];
    lData.type.radio = [
      { id: 'cpu', name: 'Загрузка ЦП' },
      { id: 'clients', name: 'Количество клиентов' },
      { id: 'bytes', name: 'Байты' },
      { id: 'rate', name: 'Скорость' },
      { id: 'ping', name: 'Ping' }
    ];

    return Object.create(visual).extend({
      title: 'Графики (Общие)',
      name: 'deviceGraph',
      icon: 'stats',
      $el: $('<div>'),
      create: function(el, opt) {
        var me = this;
        visual.create.apply(me, arguments);

        advData = opt.advData;

        this.fmodel = new Backbone.Model({ period: '2day' });
        this.fmodel.on('change:device change:period refresh', function() {
            var url = darsan.makeUrl('','device', '/' + me.fmodel.get('type') + '/' + me.fmodel.get('device') + '/graph'),
            token = encodeURIComponent(localStorage.getItem('radix-token'));

            var param = lData.type[me.fmodel.get('type')];
            if (!param) console.error('Graph config for type ' + me.fmodel.get('type') + ' not found!');

          var graph = [];
          _.map(param, function(v) {
            graph.push({
              url: url + '/' + v.id + '?period=' + me.fmodel.get('period') + '&darsan2=' + token,
              name: v.name,
              id: v.id
            });
          });
	  me.$el.html(_.template(mainTemplate)({ graph: graph, lData: lData, fmodel: me.fmodel.toJSON() }));
        });

        me.$el.on('change', '[name="period"]', function(e) {
          me.fmodel.set({ period: $(e.currentTarget).val() });
        });

        me.$el.on('click', '#refresh', function(e) {
          me.fmodel.trigger('refresh');
        });
      },
      setState: function(state) {
        var me = this;
        me.state = state;

        this.fmodel.set(state);
      }
    });
  }
);
